import React, {useState, useRef, useEffect} from "react";
import FormControl from 'react-bootstrap/lib/FormControl';
import ControlLabel from 'react-bootstrap/lib/ControlLabel';
import MaterialTable, { MTablePagination, MTableToolbar } from "material-table";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";

import before7DayDateIndianFormat from '../../../helper/before7DayDateIndianFormat'
import currentDateIndianFormat from '../../../helper/currentDateIndianFormat'
import { getProcessUnprocessedTask, uploadTeleTasks, uploadCsvtoSqs } from './service'
import DropLeads from './dropLeads'
import TaskProps from './taskprops'
import FrappButton from '../../../components/utilities/FrappButton';
import ThreeDotLoader from '../../../components/utilities/ThreeDotLoader';
import {UploadIcon, RefreshIcon} from '../../../assets/images'
import handleErrorMessage from '../../../helper/handleErrorMessage'
import {DownloadIcon,AlertIcon} from '../../../assets/images'
import { CSVLink } from "react-csv";
import Papa from "papaparse"

const UPDATE_LEADS = 'UPDATE LEAD'
const UPLOAD_LEADS = 'UPLOAD LEAD'

const TeleTask = (props) => {
    const [teleTaskModal, setTeleTaskModal] = useState({
        data: {},
        processUnprocessedData: [],
        uploadBtnDisabled: true,
        isTaskFetched: false,
        projectData: props.data
    })
    const [updateLeads, setUpdateLeads] = useState({
        data: {},
        processUnprocessedData: [],
        updateBtnDisabled: true,
        isTaskFetched: false,
        projectData: props.data
    })
    const [ attempts, setAttempts] = useState(5)
    const [ maxConnectedAttempts, setMaxConnectedAttempts] = useState(1)
    const [ maxConnectedAttemptsUpdateLeads, setMaxConnectedAttemptsUpdateLeads] = useState(1)
    const [ toggleMaxConnected, setToggleMaxConnected ] = useState(false)
    const [ toggleMaxConnectedUpdateLeads, setToggleMaxConnectedUpdateLeads ] = useState(false)
    const [dropLeadAttempts, setDropLeadsAttempts] = useState(1)
    const [processTeleTasks, setProcessTeleTasks] = useState('')
    const [displayUploadFeedback, setDisplayUploadFeedback] = useState({status:'',message:''})
    const [displayUpdateFeedback, setDisplayUpdateFeedback] = useState({status:'',message:''})

    const headers = props.data.taskProps?props.data.taskProps.map(element => {
        return {[element.name]:" "}
    }):[{Mobile:" "}]

    useEffect(()=>{
        getTaskCount(props.data)
    }, [])

    const getTaskCount = async(teleproject) => {
        const payloadData = {
            teleproject: teleproject.id,
            from: before7DayDateIndianFormat(),
            to: currentDateIndianFormat()
        }
        let processedUnprocessedTask = ''
        try{
            processedUnprocessedTask = await getProcessUnprocessedTask(payloadData)
            setTeleTaskModal({
                ...teleTaskModal,
                processUnprocessedData: processedUnprocessedTask.data,
                isTaskFetched: true
            })
        }catch(err){
            if(err.response){
                console.error((err.response && err.response.data) || "Unexpected Error!")
            }
            if(err)
            toast(handleErrorMessage(err))
            setTeleTaskModal({
                ...teleTaskModal,
                isTaskFetched: true
            })
        }
    }

    const updateProjectData = (data) => {
        let tempProjectData = {
            ...teleTaskModal.projectData,
            allowDuplicateTasks: data.allowDuplicateTasks,
            taskProps: data.taskProps
        }
        props.updateProjectData(tempProjectData)
    }

    const setCsvFile = (e, type) => {
        const file = e.target.files[0] ? e.target.files[0] :''
        let nameForQueue = 'teletask-preupload'
        if (file && file.name && file.name.match(/\.(csv)$/)) {
            if(type === UPLOAD_LEADS){
                if(e.target.files.length > 0){
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        Papa.parse(file, {
                            complete: (result) => {
                              // The parsed data is available in the `data` property
                             // Extract and set column headers
                            const headers = result.meta.fields || [];
                            const headers_small_case = headers && headers.map(head => head.toLowerCase())
                          
                            if(headers_small_case.includes('lead id')){
                                const extractHeader = headers && headers.find(head => head.toLowerCase() == 'lead id')
                                setDisplayUploadFeedback({status:"failed", message: `Please avoid adding the ${extractHeader} as CSV header`})
                                setTeleTaskModal({
                                    ...teleTaskModal,
                                    uploadBtnDisabled: true
                                })
                                return false
                            }
                            },
                            header: true, // If the CSV has a header row
                            dynamicTyping: true, // Convert numerical values to numbers
                          });
                    }
                    // Read the file as text
                    reader.readAsText(file);
                }
                setTeleTaskModal({
                    ...teleTaskModal,
                    uploadBtnDisabled: false
                })
            }
            if(type === UPDATE_LEADS){
                setUpdateLeads({
                    ...updateLeads,
                    updateBtnDisabled: false
                })
            }
            const taskData = new FormData();
            taskData.append('teleproject', props.data.id);
            taskData.append('queuename', nameForQueue);
            taskData.append('additional', JSON.stringify({
                teleproject: props.data.id,
                attempts: Number(attempts),
                action: type === UPDATE_LEADS ? 'update' : 'create'
            }))
            taskData.append('file', file);
            setProcessTeleTasks(taskData)
        }
        else {
            e.target.value = null;
            if(type === UPLOAD_LEADS){
                setTeleTaskModal({
                    ...teleTaskModal,
                    uploadBtnDisabled: true
                })
            }
            if(type === UPDATE_LEADS){
                setUpdateLeads({
                    ...updateLeads,
                    updateBtnDisabled: true
                })
            }
            alert('Please upload the CSV file')
        }
    }

    const uploadCsv = async(attempts, type) => {
           //sync the formdata with latest attempts captured
            let additonal = JSON.parse(processTeleTasks.get('additional'))
            additonal.attempts = Number(attempts)
            if(type === UPLOAD_LEADS){
                if(toggleMaxConnected){
                    additonal.connectedAttempts = Number(maxConnectedAttempts)
                }else{
                    const {connectedAttempts, ...rest} = additonal
                    additonal = rest
                }
            }
            if(type === UPDATE_LEADS){
                if(toggleMaxConnectedUpdateLeads){
                    additonal.connectedAttempts = Number(maxConnectedAttemptsUpdateLeads)
                }else{
                    const {connectedAttempts, ...rest} = additonal
                    additonal = rest
                }
            }
            processTeleTasks.set('additional', JSON.stringify(additonal));
            //end of sync
            if(type === UPLOAD_LEADS){
                setTeleTaskModal({
                    ...teleTaskModal,
                    data:{
                        tmp: true,
                       msg: 'Uploading CSV..'
                    },
                    uploadBtnDisabled: true
                })
            }
            if(type === UPDATE_LEADS){
                setUpdateLeads({
                    ...updateLeads,
                    data:{
                        tmp: true,
                       msg: 'Uploading CSV..'
                    },
                    updateBtnDisabled: true
                })
            }
            
            let result = ''
            try{
                result = await uploadCsvtoSqs(processTeleTasks)
                if (result.status === 200) {
                    if(type === UPLOAD_LEADS){
                    setTeleTaskModal({
                        ...teleTaskModal,
                        data:{
                            result: result.data.msg,
                            resultType: result.data.status
                        },
                        uploadBtnDisabled: false
                    })
                    setDisplayUploadFeedback({status:result.data.status, message:result.data.msg})
                    }
                    if(type === UPDATE_LEADS){
                        setUpdateLeads({
                            ...updateLeads,
                            data:{
                                result: result.data.msg,
                                resultType: result.data.status
                            },
                            updateBtnDisabled: false
                        })
                    setDisplayUpdateFeedback({status:result.data.status, message:result.data.msg})
                    }
                }
            }catch(err){
                console.log(err)
                if(type === UPLOAD_LEADS)
                setDisplayUploadFeedback({status:"failed", message:err.response.data.message})
                if(type === UPDATE_LEADS)
                setDisplayUpdateFeedback({status:"failed", message:err.response.data.message})
                if(err){
                toast(handleErrorMessage(err))
                }
                if(type === UPLOAD_LEADS){
                setTeleTaskModal({
                    ...teleTaskModal,
                    data: {
                        result: err.response && err.response.data && 
                        err.response.data.msg ? err.response.data.msg : 'Something went wrong while uploading',
                        resultType: 'failure'
                    },
                    uploadBtnDisabled: false
                })
                }
                if(type === UPDATE_LEADS){
                    setUpdateLeads({
                        ...updateLeads,
                        data:{
                            result: err.response && err.response.data && 
                            err.response.data.msg ? err.response.data.msg : 'Something went wrong while uploading',
                            resultType: 'failure'
                        },
                        updateBtnDisabled: false
                    })
                }
            }
    }

    const checkErrorMessageUpload = () => {
        if(!toggleMaxConnected){
            return false
        }
        if(Number(maxConnectedAttempts) >= Number(attempts)){
            if(Number(maxConnectedAttempts) == 1 && Number(attempts) == 1){
                return false
            }
            return true
        }else{
            return false
        }
    }
    // deprecating the logic as the max connected attempts limit is removed
    const checkErrorMessageUpdate = () => {
        if(!toggleMaxConnectedUpdateLeads){
            return false
        }
        if(Number(maxConnectedAttemptsUpdateLeads) >= Number(dropLeadAttempts)){
            if(Number(maxConnectedAttemptsUpdateLeads) == 1 && Number(dropLeadAttempts) == 1){
                return false
            }
            return true
        }else{
            return false
        }
    }

    const { data, msg, errCode, projectData, processUnprocessedData, uploadBtnDisabled, isTaskFetched} = teleTaskModal
    const { updateBtnDisabled } = updateLeads

    return(
        <div className='tele-task-wrapper'>
            <div className='upload-csv'>
                <div className='csv-heading'>Upload Leads</div>
                    <ControlLabel className="csv">
                    <div className='header-csv'>
                        CSV headers must be as per the defined Schema 
                        <CSVLink data={headers} filename={`leads-upload-csv-${props.data.id}.csv`} >
                            <div className="download-btn" >
                                <img title="Download Template" src={DownloadIcon} alt='download'/>
                            </div>
                        </CSVLink>
                        </div>
                        <table className="headers-table"><tr>{
                            props.data.taskProps.length>1? 
                            props.data.taskProps.map(header => {
                                return header.optional==true ? <th className="headers-list">{header.name}</th> :
                                <th className="headers-list">{header.name}<span style={{color:"red"}}>*</span></th>
                            }):
                            <th className="headers-list">Mobile<span style={{color:"red"}}>*</span></th>
                        }</tr></table>
                    </ControlLabel>
                    <div className='csv-heading'>
                    Attach csv file
                    </div>
                    <div className='teletask-csv-upload-wrapper'>
                        <div className='teletask-csv-row'>
                            <div className='dotted-border'>
                                <FormControl type="file" id='dropleadinputFile' onChange={(e) => {
                                    setDisplayUploadFeedback({status:'', message: ''})
                                    setCsvFile(e, UPLOAD_LEADS)
                                }}/>
                            </div>
                        </div>
                    </div>

                    <div className='attempts-section'>
                    <div className='attempts'>
                        <span>Attempts:</span>
                        <input type='number' value={attempts} min={1}
                        onChange={(e)=>setAttempts(e.target.value)}/>
                    </div>
                    {
                        attempts < 1 || attempts > 15
                        ? 
                        <div className='error'>Attempt range must be within 1 and 15</div>
                        : null
                    }
                    <hr/>
                    {
                        toggleMaxConnected && <div className='attempts'>
                        <span>Max Connected Attempts:</span>
                        <input type='number' value={maxConnectedAttempts} min={1}
                        onChange={(e)=>setMaxConnectedAttempts(e.target.value)}/>
                        </div>
                    }
                    {/* remove the limit on the max connected attempts limit  */}
                    {/* {
                         toggleMaxConnected && checkErrorMessageUpload() 
                        ? 
                        <div className='error'>Max connected attempts should be less than the attempts</div>
                        : null
                    } */}
                    {toggleMaxConnected && <hr/>}
                    <label><input type="checkbox" checked={toggleMaxConnected} onChange={(e)=> {
                        setToggleMaxConnected(e.target.checked)
                    }} /> <span>Add max connected attempts</span></label>
                    </div>
                    {
                    
                        displayUploadFeedback.status == "success" ? <div className="upload-success">File uploaded successfully !</div> : 
                        displayUploadFeedback.status == "failed" ? <div className="upload-fail"><img src={AlertIcon} alt='warning'/>{displayUploadFeedback.message}</div> : ''
                    }

                    <div className='upload-btn-wrapper'>
                    <FrappButton className="submit" id="new-btn" handler={() => {
                        setDisplayUploadFeedback({status:'',message:''})
                        uploadCsv(attempts, UPLOAD_LEADS)
                    }}
                    disabled={uploadBtnDisabled || ((attempts < 1 || attempts > 15) ? true : false)}
                    >Upload</FrappButton>
                    </div>
                  
                    <div className='previous-records'>
                       <div className='refresh-task-count'
                            onClick={()=>{
                                setTeleTaskModal({
                                    ...teleTaskModal,
                                    isTaskFetched: false
                                })
                                getTaskCount(teleTaskModal.projectData)
                            }}>
                            <img src={RefreshIcon} />
                            Refresh
                        </div>
                        {
                            !isTaskFetched ? 
                        <ThreeDotLoader/>
                            :
                            <MaterialTable
                            localization={{
                            body: {
                            emptyDataSourceMessage: 'No records to display',
                            }
                            }}
                            columns={[
                            {
                            title: "Date",
                            field: "date",
                            render: (rowData => {
                            return <div>{moment(rowData.date).format("DD/MM/YYYY")}</div>
                            }),
                            width: "25%"
                            },
                            {
                            title: "Processed Lead",
                            field: "processedTasksCount",
                            width: "25%"
                            },
                            {
                            title: "Unprocessed Lead", 
                            field: "unprocessedTasksCount",
                            width: "25%"
                            }
        
                            ]}
                            data={processUnprocessedData}
                            actions={[]}
                            components={{}}
                            options={{
                            maxBodyHeight: 350,
                            // selection:true,
                            sorting: false,
                            filtering: false,
                            grouping: false,
                            toolbar: false,
                            paging: false,
                            exportButton: false,
                            exportAllData: false,
                            columnsButton: true,
                            emptyRowsWhenPaging: false,
                            cellStyle: {
                            fontSize: '14px',
                            color: 'black'
                            }, headerStyle: {
                            backgroundColor: '#E7FAF2',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            boxShadow: 'none'
        
                            }
                            }}
                            />
                        }
                </div>
            </div>
            <div className="divider"></div>
            <div className='upload-csv'>
                <div className='csv-heading'>Leads Manipulation</div>
                    <ControlLabel className="csv">
                        <div className='header-csv'>
                        CSV headers must be as per the defined Schema 
                        <CSVLink data={headers} filename={`leads-upload-csv-${props.data.id}.csv`} >
                            <div className="download-btn" >
                                <img title="Download Template" src={DownloadIcon} alt='download'/>
                            </div>
                        </CSVLink>
                        </div>
                        <table className="headers-table"><tr>{
                            <th className="headers-list">lead id<span style={{color:"red"}}>*</span></th>
                        }</tr></table>
                    </ControlLabel>
                    <div className='csv-heading'>
                    Attach csv file
                    </div>
                    <div className='teletask-csv-upload-wrapper'>
                        <div className='teletask-csv-row'>
                            <div className='dotted-border'>
                                <FormControl type="file" id='dropleadinputFile' onChange={(e) => setCsvFile(e, UPDATE_LEADS)}/>
                            </div>
                        </div>
                    </div>

                    <div className='attempts-section'>
                    <div className='attempts'>
                        <span>Attempts:</span>
                        <input type='number' value={dropLeadAttempts}
                        onChange={(e)=>setDropLeadsAttempts(e.target.value)}/>
                    </div>
                    {
                        dropLeadAttempts < 0 || dropLeadAttempts > 15
                        ? 
                        <div className='error'>Attempt range must be within 0 and 15</div>
                        : null
                    }
                    <hr/>
                    {
                        toggleMaxConnectedUpdateLeads && <div className='attempts'>
                        <span>Max Connected Attempts:</span>
                        <input type='number' value={maxConnectedAttemptsUpdateLeads} min={1}
                        onChange={(e)=>setMaxConnectedAttemptsUpdateLeads(e.target.value)}/>
                        </div>
                    }
                    {/* remove the limit on the max connected attempts limit  */}
                    {/* {
                         toggleMaxConnectedUpdateLeads && checkErrorMessageUpdate()
                        ? 
                        <div className='error'>Max connected attempts should be less than the attempts</div>
                        : null
                    } */}
                    {toggleMaxConnectedUpdateLeads && <hr/>}
                    <label><input type="checkbox" checked={toggleMaxConnectedUpdateLeads} onChange={(e)=> {
                        setToggleMaxConnectedUpdateLeads(e.target.checked)
                    }} /> <span>Add max connected attempts</span></label>
                    </div>
                    {
                    
                        displayUpdateFeedback.status == "success" ? <div className="upload-success">File uploaded successfully !</div> : 
                        displayUpdateFeedback.status == "failed" ? <div className="upload-fail"><img src={AlertIcon} alt='warning'/>{displayUpdateFeedback.message}</div> : ''
                    }

                    <div className='upload-btn-wrapper'>
                    <FrappButton className="submit" id="new-btn" handler={() => {
                        setDisplayUpdateFeedback({status:'',message:''})
                        uploadCsv(dropLeadAttempts, UPDATE_LEADS)
                    }}
                    disabled={updateBtnDisabled || ((dropLeadAttempts < 0 || dropLeadAttempts > 15) ? true : false)}
                    >Update</FrappButton>
                    </div>
            </div>
            <ToastContainer draggable={true} />
        </div>
    )
}

export default TeleTask