import React, { useState, useContext, useEffect} from "react";
import styles from "../../../assets/stylesheets/pages/tools/tools.module.scss"
import FutworkPanel from "../../../uicomponents/panel"
import { ExoContext } from './exoContext' 
import { EmptyCollection } from "../../../assets/images";
import FutworkButton from "../../../uicomponents/button"
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";

import { CloseIcon } from "../../../assets/images";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark, faEllipsisVertical, faChevronLeft, faLink } from '@fortawesome/free-solid-svg-icons'

import {createUpdateExophones} from './service'
import useApi from "../../../hooks/useApi";

import Switch from "react-switch";
import { ToastContainer, toast } from "react-toastify";
import Popup from "../../../uicomponents/popup";

import ProjectList from "./projectList";

import DeleteExophone from "./deleteExophone";
import Accordion from "../../../uicomponents/panel/accordion";
import handleErrorMessage from "../../../helper/handleErrorMessage";

const keys = {
    primary: "Primary number*",
    primaryRegion: "Primary number region*",
    primaryOperator: "Primary number operator*",
    primaryTruecaller: "Truecaller Whitelisted",
    backup: "Backup number*",
    backupRegion: "Backup number region*",
    backupOperator: "Backup number operator*",
    backupTruecaller: "Truecaller Whitelisted",
    outgoing: "Primary number*",
    outgoingRegion: "Primary number region*",
    outgoingOperator: "Primary number operator*",
    outgoingTruecaller: "Truecaller Whitelisted",
    outgoingBackup: "Backup number*",
    outgoingBackupRegion: "Backup number region*",
    outgoingBackupOperator: "Backup number operator*",
    outgoingBackupTruecaller: "Truecaller Whitelisted"
}
const ExophoneList = (props) => {
    const createUpdateExophonesApi = useApi(createUpdateExophones)
    const { viewExophones, setViewExophones, exoRegion, networkOperator, whiteListingOptions, 
            exotelAcc, setExotelAcc ,
            goToPooList,
            projectList, setProjectList,
            renderProjectTabs,
            activeTab, setActiveTab,
            deleteExophone, setDeleteExophone
    } = useContext(ExoContext)
    const {data: {numbers}} = viewExophones


    const {data} = viewExophones

    const [updatedArry, setUpdatedArray] = useState([])

    const [exophoneArray, setExophoneArray] = useState(
        numbers && numbers.length > 0 ? numbers.map((num, idx)=> {
            return{
                id: idx+1,
                outgoingnumberactive: false,
                incomingnumberactive: true,
                isEdit: false,
                ...num,
                errormsg: ''
            }
        }): numbers
    )


    const updateForm = (data, key, value) => {
        let findExo = exophoneArray && exophoneArray.find(exo => exo.id == data.id)
        findExo[key] = value

        let updatedForm  = exophoneArray && exophoneArray.map(exo => {
            if(exo.id == data.id){
                exo = findExo
            }
            return exo
        })
        setExophoneArray(updatedForm)
    }

    const cancelFormChange = () => {
        let updatedForm  = exophoneArray && exophoneArray.map(exo => {
            exo.isEdit = false
            return exo
        })
        setExophoneArray(numbers && numbers.length > 0 ? numbers.map((num, idx)=> {
            return{
                id: idx+1,
                outgoingnumberactive: false,
                incomingnumberactive: true,
                isEdit: false,
                ...num,
                errormsg: ''
            }
        }): numbers)
    }

    const updateIncomingRegion = (data, value) => {
        let findExo = exophoneArray.find(exo => exo.id == data.id)
        findExo['primaryRegion'] = value
        findExo['backupRegion'] = value

        findExo['outgoingRegion'] = value
        findExo['outgoingBackupRegion'] = value

        let updatedForm  = exophoneArray.map(exo => {
            if(exo.id == data.id){
                exo = findExo
            }
            return exo
        })
        setExophoneArray(updatedForm)
    }

    const updateOutgoingRegion = (data, value) => {
        let findExo = exophoneArray.find(exo => exo.id == data.id)
        findExo['outgoingRegion'] = value
        findExo['outgoingBackupRegion'] = value

        let updatedForm  = exophoneArray.map(exo => {
            if(exo.id == data.id){
                exo = findExo
            }
            return exo
        })
        setExophoneArray(updatedForm)
    }

    const removeOutgoingnumber = (data) => {
        const { id } = data
        const updatedForm  = exophoneArray.map(exo => {
            if(exo.id == id){
                Object.keys(exo).forEach(function(key){
                    key.indexOf("o") == 0 && delete exo[key];
                  });
            }
            return exo
        })
        setExophoneArray(updatedForm)
    }

    const validateData = (data) => {
        const {primary, primaryRegion, backup, backupRegion, label, outgoingnumberactive} = data
        if(!primary || !primaryRegion || !backup || !backupRegion || !label){
            let findExo = exophoneArray.find(exo => exo.id == data.id)
            findExo['errormsg'] = 'Fill the mandatory fields like exophone title, incoming - primary & backup number & primary & backup region'
            
            let updatedForm  = exophoneArray.map(exo => {
                if(exo.id == data.id){
                    exo = findExo
                }
                return exo
            })
            setExophoneArray(updatedForm)
            return false
        }else if(outgoingnumberactive){
            const {outgoingBackup, outgoingBackupRegion} = data
            if(!outgoingBackup || !outgoingBackupRegion){
                let findExo = exophoneArray.find(exo => exo.id == data.id)
                findExo['errormsg'] = 'Fill the mandatory fields like outgoing - primary & backup & primary & backup region'
        
                let updatedForm  = exophoneArray.map(exo => {
                    if(exo.id == data.id){
                        exo = findExo
                    }
                    return exo
                })
                setExophoneArray(updatedForm)
                return false
            }
            return true
        }else{
            const activeExophoneCount = exophoneArray.filter(exo => {
                if(exo.active){
                    return exo
                }
            })
            let findExo = exophoneArray && exophoneArray.find(exo => exo.id == data.id)
            if(activeExophoneCount && activeExophoneCount.length > 1){
                    findExo['errormsg'] = 'Not more than one exophone can be active in the pool at the same instance'
                    let updatedForm  = exophoneArray.map(exo => {
                        if(exo.id == data.id){
                            exo = findExo
                        }
                        return exo
                    })
                    setExophoneArray(updatedForm)
                    return false
            }else{
                setExophoneArray(exophoneArray.map(exo => {
                    exo.errormsg = ''
                    return exo
                }))
                return true
            }
        }
    }
    
    const validateDataInBulk = (array) => {
        let res = array && array.map(data => {
            const {primary, primaryRegion, backup, backupRegion, label, outgoingnumberactive} = data
            if(!primary || !primaryRegion || !backup || !backupRegion || !label){
                data['errormsg'] = 'Fill the mandatory fields like exophone title, incoming - primary & backup number & primary & backup region'
                
            }else if(outgoingnumberactive){
                const {outgoingBackup, outgoingBackupRegion} = data
                if(!outgoingBackup || !outgoingBackupRegion){
                    data['errormsg'] = 'Fill the mandatory fields like outgoing - primary & backup & primary & backup region'
                }
            }else{
                const activeExophoneCount = exophoneArray.filter(exo => {
                    if(exo.active){
                        return exo
                    }
                })
                if(activeExophoneCount && activeExophoneCount.length > 1){
                        data['errormsg'] = 'Not more than one exophone can be active in the pool at the same instance'
            
                }else{
                    data['errormsg'] = ''
                }
            }
            return data
        })
        setExophoneArray(res)
    }

    const saveExophone = () => {
        const {data:{accountId, poolId}} = viewExophones
        const tempExophoneArray = [...exophoneArray]
        const isNotFilled = tempExophoneArray && tempExophoneArray.map(exo => {
             if(!validateData(exo)) 
             return true
        }).filter(Boolean)
        if(isNotFilled && isNotFilled.length > 0){
            toast(`One or more exophone mandatory field (primary number | primary region | backup number | backup region ) is empty`)
            return false
        }
        const updateArray = exophoneArray.map(exo => {
            const {outgoingnumberactive, incomingnumberactive, id, isEdit, errormsg, isnew, isExpand,  ...rest} = exo
            rest.primary = `N:${rest.primary}`
            rest.backup = `N:${rest.backup}`
            return exo = rest
        })
        const payload = {
            accountId,
            poolId,
            numbers: updateArray
        }
        createUpdateExophonesApi.request(payload)
    }

    const deleteExo = (data) => {
        if(data && data.isnew){
            let removeunwantedExo = exophoneArray.filter(exo => {
                if(exo.id !== data.id)
                return exo
            })
            setExophoneArray(removeunwantedExo)
            return false
        }
        let updatedArray = exophoneArray.filter(exo => {
            if(exo.id !== data.id)
            return exo
        })
        let removeunnecessarytext = updatedArray && updatedArray.map(exo => {
            const {outgoingnumberactive, incomingnumberactive, id, isEdit, errormsg, isnew, isExpand, ...rest} = exo
            exo = rest
            return exo
        })
        setUpdatedArray(updatedArray)
        const {data:{accountId, poolId}} = viewExophones
        const payload = {
            accountId,
            poolId,
            numbers: removeunnecessarytext
        }
        createUpdateExophonesApi.request(payload)
    }   

    const renderProjectsList = () => {
        const {status} = projectList
        return (
            <Popup
                show={status}
                closePopup={() => {
                    setProjectList({ status: false, data: {} })
                }}
                size='lg'
                heading={renderProjectTabs()}
            >
                <ProjectList />
            </Popup>
        )

    }

    const renderDeleteExophone = () => {
        const { status } = deleteExophone
        return (
            <Popup
                show={status}
                closePopup={() => {
                    setDeleteExophone({ status: false, data: {} })
                }}
                size='sm'
                heading={''}
            >
                <DeleteExophone callDeleteFn={deleteExo}/>
            </Popup>
        )
    }

    const handlePopups = () => {
        if(projectList.status) return renderProjectsList()
        if(deleteExophone.status) return renderDeleteExophone()
    }

    const panelForm = (data) => {
        return(
            <>
            <div className={styles.panel_wrapper}>
                <div className={styles.outgoing_number}>
                    <div className={styles.bold_text}>Exophone number set up</div>
                    <div className={styles.exo_grid}>
                        <div>Primary number*</div>
                        <div>Primary number region*</div>
                        <div>Primary number operator</div>
                        <div>Truecaller Whitelisted</div>
                    </div>
                    <div className={styles.exo_grid}>
                        <div>
                        <input type="text" value={data.primary}
                        className={styles.exo_number}
                        onChange={(e) => updateForm(data, 'primary', e.target.value)} readOnly={!data.isEdit}/>
                       </div>
                        <div>
                            <select name="primary-region" value={data.primaryRegion} onChange={(e) => updateIncomingRegion(data, e.target.value)}
                            disabled={!data.isEdit}>
                                <option value="" hidden={data.primaryRegion}>Select</option>
                                {
                                    exoRegion.map(region => {
                                        return <option value={region.value} key={region.value}>{region.label}</option>
                                    })
                                }
                            </select>


                            {/* <select name="result" id={`param-result-${idx + 1}`} onChange={(e) => changeResultOfBehavior(i, e.target.value)}
                            value={i && i.result}>
                            {
                            resultOptions.map((option => {
                            return <option value={option.value}
                            hidden={i.isFatal && option.label == 'NA'} key={option.value}>{option.label}</option>
                            }))
                            }
                            </select> */}

                            {/* <DropdownButton
                            title={data.primaryRegion ? data.primaryRegion : 'Select'}
                            placeholder='Choose template'
                            key={data.id}
                            id='dropdown-basic'
                            className={styles.selector} */}
                            {/* > */}
                            {/* {
                            templateDropdownList && templateDropdownList.map((value, index) =>
                            <MenuItem key={`dropdown-${index}`} eventKey={index + 1}
                            onSelect={() => selectTemplate(value, idx)}
                            className={templateDropdownListClass(value.id, item.templateId)}
                            >{value.title}</MenuItem>
                            )
                            } */}
                            {/* </DropdownButton> */}
                        </div>
                        <div>

                        <select name="network-operator" value={data.primaryOperator} disabled={!data.isEdit} 
                        onChange={(e) => updateForm(data, 'primaryOperator' , e.target.value)}>
                            <option value="" hidden={data.primaryOperator}>Select</option>
                                {
                                    networkOperator.map(region => {
                                        return <option value={region} key={region}>{region}</option>
                                    })
                                }
                        </select>
                        {/* <DropdownButton
                            title={data.primaryOperator ? data.primaryOperator : 'Select'}
                            placeholder='Choose template'
                            key={data.id}
                            id='dropdown-basic'
                            > */}
                            {/* {
                            templateDropdownList && templateDropdownList.map((value, index) =>
                            <MenuItem key={`dropdown-${index}`} eventKey={index + 1}
                            onSelect={() => selectTemplate(value, idx)}
                            className={templateDropdownListClass(value.id, item.templateId)}
                            >{value.title}</MenuItem>
                            )
                            } */}
                            {/* </DropdownButton> */}
                        </div>
                        <div>
                        <select name="primary-region" value={data.primaryTruecaller} disabled={!data.isEdit} 
                        onChange={(e) => updateForm(data, 'primaryTruecaller' , e.target.value)}>
                            {
                                whiteListingOptions.map(option => {
                                    return <option value={option.value} key={option.value}>{option.label}</option>
                                })
                            }
                        </select>
                        {/* <DropdownButton
                            title={data.primaryTruecaller ? data.primaryTruecaller : 'Select'}
                            placeholder='Choose template'
                            key={data.id}
                            id='dropdown-basic'
                            > */}
                            {/* {
                            templateDropdownList && templateDropdownList.map((value, index) =>
                            <MenuItem key={`dropdown-${index}`} eventKey={index + 1}
                            onSelect={() => selectTemplate(value, idx)}
                            className={templateDropdownListClass(value.id, item.templateId)}
                            >{value.title}</MenuItem>
                            )
                            } */}
                            {/* </DropdownButton> */}
                            </div>
                    </div>

                    <div className={styles.exo_grid}>
                        <div>Backup number*</div>
                        <div>Backup number region*</div>
                        <div>Backup number operator</div>
                        <div>Truecaller Whitelisted</div>
                    </div>

                    <div className={styles.exo_grid}>
                        <div><input type="text" value={data.backup}
                        className={styles.exo_number}
                        onChange={(e) => updateForm(data, 'backup', e.target.value)} readOnly={!data.isEdit}/></div>
                        <div>
                            <select name="backup-region" value={data.backupRegion} disabled={!data.isEdit} 
                            onChange={(e) => updateIncomingRegion(data, e.target.value)}>
                                <option value="" hidden={data.backupRegion}>Select</option>
                                {
                                    exoRegion.map(region => {
                                        return <option value={region.value} key={region.value}>{region.label}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div>
                        <select name="network-operator" value={data.backupOperator} disabled={!data.isEdit} 
                        onChange={(e) => updateForm(data, 'backupOperator' , e.target.value)}>
                            <option value="" hidden={data.backupOperator}>Select</option>
                                {
                                    networkOperator.map(region => {
                                        return <option value={region} key={region}>{region}</option>
                                    })
                                }
                        </select>
                        </div>
                        <div>
                        <select name="primary-region" value={data.backupTruecaller} disabled={!data.isEdit} 
                        onChange={(e) => updateForm(data, 'backupTruecaller' , e.target.value)}>
                        <option value="" hidden={data.backupTruecaller}>Select</option>
                            {
                                whiteListingOptions.map(option => {
                                    return <option value={option.value} key={option.value}>{option.label}</option>
                                })
                            }
                        </select>
                        </div>
                    </div>
                    <div className={styles['user-note']}>
                        Note: Please add a prefix '0' for domestic numbers. Ex: 07314629985 &nbsp;&nbsp;|&nbsp;&nbsp; '+' for international numbers. Ex: +17182134412 or copy the entire number from exotel dashboard
                    </div>

                </div>
                {/* {
                    (data.outgoingnumberactive || data.outgoing) ?
                    <div className={styles.outgoing_number}>
                    <div className={[styles.bold_text, styles.outcome_number_header].join(' ')}>
                        Outgoing number set up
                        <FontAwesomeIcon onClick={() => removeOutgoingnumber(data)} icon={faXmark} 
                    // className={styles['close-btn']}
                    /></div>
                    <div className={styles.exo_grid}>
                        <div>Primary number*</div>
                        <div>Primary number region*</div>
                        <div>Primary number operator</div>
                        <div>Truecaller Whitelisted</div>
                    </div>
                    <div className={styles.exo_grid}>
                        <div><input type="number"  value={data.outgoing} readOnly={!data.isEdit}
                        className={styles.exo_number}
                        onChange={(e) => updateForm(data, 'outgoing', e.target.value)}/></div>
                        <div>
                            <select name="primary-region" value={data.outgoingRegion} disabled={!data.isEdit} 
                            onChange={(e) => updateIncomingRegion(data, e.target.value)}>
                            <option value="" hidden={data.outgoingRegion}>Select</option>
                                {
                                    exoRegion.map(region => {
                                        return <option value={region.value} key={region.value}>{region.label}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div>
                        <select name="primary-region" value={data.outgoingOperator} disabled={!data.isEdit}
                        onChange={(e) => updateForm(data, 'outgoingOperator' , e.target.value)}>
                            <option value="" hidden={data.outgoingOperator}>Select</option>
                                {
                                    networkOperator.map(region => {
                                        return <option value={region} key={region}>{region}</option>
                                    })
                                }
                        </select>
                        </div>
                        <div>
                        <select name="primary-region" value={data.outgoingTruecaller} disabled={!data.isEdit}
                        onChange={(e) => updateForm(data, 'outgoingTruecaller' , e.target.value)}>
                            <option value="" hidden={data.outgoingTruecaller}>Select</option>
                            {
                                whiteListingOptions.map(option => {
                                    return <option value={option.value} key={option.value}>{option.label}</option>
                                })
                            }
                        </select>
                        </div>
                    </div>

                    <div className={styles.exo_grid}>
                        <div>Backup number*</div>
                        <div>Backup number region*</div>
                        <div>Backup number operator</div>
                        <div>Truecaller Whitelisted</div>
                    </div>

                    <div className={styles.exo_grid}>
                        <div><input type="number"  value={data.outgoingBackup} readOnly={!data.isEdit}
                        className={styles.exo_number}
                        onChange={(e) => updateForm(data, 'outgoingBackup', e.target.value)}/></div>
                        <div>
                            <select name="primary-region" value={data.outgoingBackupRegion} disabled={!data.isEdit}
                             onChange={(e) => updateIncomingRegion(data, e.target.value)}>
                            <option value="" hidden={data.outgoingBackupRegion}>Select</option>
                                {
                                    exoRegion.map(region => {
                                        return <option value={region.value} key={region.value}>{region.label}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div>
                        <select name="primary-region"  value={data.outgoingBackupOperator} disabled={!data.isEdit} 
                        onChange={(e) => updateForm(data, 'outgoingBackupOperator' , e.target.value)}>
                            <option value="" hidden={data.outgoingBackupOperator}>Select</option>
                                {
                                    networkOperator.map(region => {
                                        return <option value={region} key={region}>{region}</option>
                                    })
                                }
                        </select>
                        </div>
                        <div>
                        <select name="primary-region" value={data.outgoingBackupTruecaller} disabled={!data.isEdit} 
                        onChange={(e) => updateForm(data, 'outgoingBackupTruecaller' , e.target.value)}>
                            <option value="" hidden={data.outgoingBackupTruecaller}>Select</option>
                            {
                                whiteListingOptions.map(option => {
                                    return <option value={option.value} key={option.value}>{option.label}</option>
                                })
                            }
                        </select>
                        </div>
                    </div>
                    </div>
                    :
                    viewExophones && viewExophones.data && viewExophones.data.poolType.toLowerCase() == 'incoming' && <div className={styles.add_incoming_number}>
                    <div className={styles.bold_text}>Outgoing number set up</div>
                    <img src={CloseIcon} />
                    <div className={styles.cta_add_incoming_number} onClick={() => {
                        setExophoneArray(
                            exophoneArray && exophoneArray.map(exo => {
                                if(exo.id == data.id){
                                    // exo.outgoingnumberactive = true
                                    exo = {
                                       ...exo,
                                       "outgoingnumberactive": true,
                                        "outgoing": "",
                                        "outgoingRegion": "",
                                        "outgoingOperator": "",
                                        "outgoingTruecaller": "not_whiteListed",
                                        "outgoingBackup": "",
                                        "outgoingBackupRegion": "",
                                        "outgoingBackupOperator": "",
                                        "outgoingBackupTruecaller": "not_whiteListed",
                                        "errormsg": ""
                                    }
                                    
                                }
                                return exo
                            })
                        )
                        // setExophoneArray(exophoneArray.map(item => {
                        //     if(item.id == data.id){
                        //         item.outgoingnumberactive = true
                        //     }
                        //     return item
                        // }))
                    }}>Click here to setup outgoing number</div>
                    </div>
                } */}
                {
                    data.errormsg && <div className={styles['error-msg']}>{data.errormsg}</div>
                }
            </div>
            {/* <div className={styles.cta_action_bottom}>

            <Switch onChange={(e) => updateForm(data, 'active', e)} checked={data.active}
            // disabled={this.state.isEncryptionDisabled} 
            // className={this.state.isEncryptionDisabled ? 'disabled-switch' : ''}
            />
            <FutworkButton
            buttonSize='sm'
            buttonStyle='primary--solid'
            onClick={() => saveExophone()}
            >Save</FutworkButton>

            <FutworkButton
            buttonSize='sm'
            buttonStyle='primary--outline'
            onClick={() => deleteExophone(data)}
            >Delete</FutworkButton>
            </div> */}
            </>
        )
    }


    const { data: exophones, loading, error } = createUpdateExophonesApi || {}

    useEffect(()=>{
        if(error)
        toast(handleErrorMessage(error))
    },[error])

    useEffect(() => {
        if (exophones) {
            const { data } = exophones
            const {data: {poolId}} = viewExophones

           const updatePool = exotelAcc.map(accnt => {
            if (accnt.data && accnt.data.id == data.id) {
                accnt.data.pool = data.pool
            }
            return accnt
            })
            setExotelAcc(updatePool)

            const findPool = data && data.pool.find(each => {
                if(each.poolId == poolId){
                    return each
                }
            })
            setViewExophones({
                status: true,
                data: {
                    ...viewExophones.data,
                    numbers: findPool.numbers
                }
            })

            setActiveTab({
                label: activeTab.label,
                value: activeTab.value,
                data: {
                    ...activeTab.data,
                    pool: data.pool
                }
            })

            
            if(loading == false && exophones){
                if(updatedArry && updatedArry.length > 0){
                    setExophoneArray(updatedArry)
                    setUpdatedArray([])
                    setDeleteExophone({
                        status: false,
                        data: {}
                    })
                    toast(`Exophone deleted successfully`)

                }else{
                    toast(`Exophones saved successfully`)

                    let result = exophoneArray.map(exo => {
                        const {isnew, ...rest} = exo
                        rest.isEdit = false
                        exo = rest
                        return exo
                    })
                    setExophoneArray(result)

                }
            }
        }
    }, [!loading && exophones !== null])

    const checkIfExophoneActive = (numbers) => {
        const activenumber = [...numbers].find(number => number.active == true)
        if (activenumber) {
            const { label, primary } = activenumber || {}
            return `${primary} (${label})` || 'NA'
        }
        else return 'NA'
    }

    const checkIfEditisTrue = () => {
        const result = exophoneArray && exophoneArray.find(({isEdit}) => isEdit)
        return result ? result.isEdit : false
    }

    return (
        <div className={styles.exophones_list_wrapper}>
            <div className={styles.header}>
            <div className={styles.arrow} onClick={goToPooList}><FontAwesomeIcon icon={faChevronLeft} /></div>
            <div className={styles.pool_name}>
                <div>
                {data && data.poolName}
                </div>
                <div className={[styles.tag, data && data.poolType ? styles[data.poolType.toLowerCase()] : undefined].join(' ')}>
                    {data.poolType}
                </div>
            </div>
            </div>

            <div className={styles.header_section}>

            <div className={styles.subheading}>
                <div>Brand: {data && data.brandName}</div>
                <div>Projects linked: {data && data.linkedProjects || 0} 
                    <FontAwesomeIcon icon={faLink} className={styles.link_project_icon} 
                    onClick={() => setProjectList({...projectList, status: true})}/>
                </div>
                <div>Exophones: {data && data.numbers && data.numbers.length}</div>
                <div>Active exophone: {data.numbers ? checkIfExophoneActive(data.numbers) : 'NA'}</div>
            </div>

            
            {checkIfEditisTrue()  && <div className={styles.cta_footer}>
                <FutworkButton
                buttonSize='sm'
                buttonStyle='primary--solid'
                onClick={() => {
                    validateDataInBulk(exophoneArray)
                    let output = ''
                    const result = exophoneArray.map(exo => {
                        if(exo.errormsg){
                            output = exo.errormsg
                            return true
                        }else{
                            return false
                        }
                    })
                    const errorInExo = result && result.some(res => res == true)
                    return errorInExo ? toast(output) : saveExophone()
                }}
                >Save</FutworkButton>

                <FutworkButton
                buttonSize='sm'
                buttonStyle='primary--outline'
                onClick={() => {
                    // updateForm(exo, 'isEdit', false)
                    cancelFormChange()
                }}
                >Cancel</FutworkButton>
            </div>}
            </div>

            {
                exophoneArray &&
                exophoneArray.length > 0 ?
                <div>
                    {/* {
                          exophoneArray.map((exo, idx) => {
                            console.log('exo', exo)
                            return  <FutworkPanel
                            arrowPosition={true}
                            title={
                                <div className={styles.panel_header}>
                                    {
                                        exo.isEdit ?
                                        <input type="text" value={exo.label ? exo.label : 'Enter exophone title here'} 
                                        onChange={(e) => updateForm(exo, 'label', e.target.value)}/>
                                        : <span>{exo.label}</span>
                                    }
                               
                                <div>
                                <FontAwesomeIcon icon={faEllipsisVertical} />
                                </div>
                                </div>
                              
                            } children={panelForm(exo)}/>
                        })
                    } */}

                    {
                         exophoneArray.map((exo, idx) => {
                            return <Accordion
                            title ={
                            exo.isEdit ? 
                            <input type="text" value={exo.label} placeholder={!exo.label && 'Enter the title here'}
                            onChange={(e) => updateForm(exo, 'label', e.target.value)}/>
                            : <span>{exo.label}</span>}
                            // title={`What are accordion components?`}
                            action={
                                // <FontAwesomeIcon icon={faEllipsisVertical} />
                                // exo.isEdit ?
                                <>
                                <div style={{display: 'flex', gap: '1em', alignItems: 'center'}}>
                                    <Switch onChange={(e) => {
                                        updateForm(exo, 'isEdit', true)
                                        updateForm(exo, 'active', e)
                                    }} checked={exo.active}
                                    // disabled={!exo.isEdit}
                                    // className={this.state.isEncryptionDisabled ? 'disabled-switch' : ''}
                                    />
                                    
                                    {/* {exo.isEdit  && <>
                                    <FutworkButton
                                    buttonSize='sm'
                                    buttonStyle='primary--solid'
                                    onClick={() => {
                                        return validateData(exo) ? saveExophone() : null
                                        // saveExophone()
                                    }}
                                    >Save</FutworkButton>

                                    <FutworkButton
                                    buttonSize='sm'
                                    buttonStyle='primary--outline'
                                    onClick={() => updateForm(exo, 'isEdit', false)}
                                    >Cancel</FutworkButton>
                                    </>} */}

                                    <DropdownButton
                                bsSize='small'
                                title={<FontAwesomeIcon icon={faEllipsisVertical} />}
                                id="action"
                                onClick={(e) => updateForm(exo, 'isExpand', true)}
                                >
                                {exo.isnew ? null : <MenuItem eventKey="1" 
                                onSelect={() => updateForm(exo, 'isEdit', true)}
                                >Edit</MenuItem>}
                                <MenuItem eventKey="2" 
                                // onSelect={() => fetchReportData(cell.row.original, 'Caller Report')}
                                onSelect={()=> {
                                    // deleteExo(exo)
                                    if(exo && exo.isnew){
                                        deleteExo(exo)
                                    }else{
                                        setDeleteExophone({
                                            status: true,
                                            data: exo
                                        })
                                    }
                                }}
                                >Delete</MenuItem>
                                </DropdownButton>
                                </div>
                                </>
                            }
                            body={panelForm(exo)}
                            id={idx}
                            data={exo}
                            // body={`Accordion components are user interface elements used for organizing and presenting content in a collapsible manner. They typically consist of a header, content, and an expand/collapse action.`} 
                            />
                        })
                    }
                     <div className={styles.cta_action_bottom}>
                        <FutworkButton
                        buttonSize='sm'
                        buttonStyle='primary--solid'
                        onClick={() => {
                            const index = exophoneArray && exophoneArray.length
                            const newExoAdded = [...exophoneArray, 
                                {
                                    "id": index+1,
                                    "outgoingnumberactive": false,
                                    "incomingnumberactive": true,
                                    "isEdit": true,
                                    "active": false,
                                    "label": "",
                                    "primary": "",
                                    "primaryRegion": "",
                                    "primaryOperator": "",
                                    "primaryTruecaller": "not_whiteListed",
                                    "backup": "",
                                    "backupRegion": "",
                                    "backupOperator": "",
                                    "backupTruecaller": "not_whiteListed",
                                    "isnew": true,
                                    "isExpand": true
                                }
                            ]
                            setExophoneArray(newExoAdded)
                        }}
                        >Add exophone</FutworkButton>
                        </div>
                </div>
                :
                <div className={styles.fallback_wrapper}>
                        <div className={styles.fallback_section}>
                            <img src={EmptyCollection} />
                            <p className={styles.text}>No exophone set up under this pool</p>
                        </div>
                        <div className={styles.cta_action_bottom}>
                        <FutworkButton
                        buttonSize='sm'
                        buttonStyle='primary--solid'
                        onClick={() => {
                            setExophoneArray([
                                {
                                        "id": 1,
                                        "outgoingnumberactive": false,
                                        "incomingnumberactive": true,
                                        "active": true,
                                        "isEdit": true,
                                        "label": "",
                                        "primary": "",
                                        "primaryRegion": "",
                                        "primaryOperator": "",
                                        "primaryTruecaller": "not_whiteListed",
                                        "backup": "",
                                        "backupRegion": "",
                                        "backupOperator": "",
                                        "backupTruecaller": "not_whiteListed",
                                        "isnew": true
                                }
                            ])
                        }}
                        >Add exophone</FutworkButton>
                        </div>
                </div>
            }
{/* 
<Accordion title={`What are accordion components?`}
action={
    // <div>
    // <FontAwesomeIcon icon={faEllipsisVertical} />
    // </div>
    'Hi'
}
body={`Accordion components are user interface elements used for organizing and presenting content in a collapsible manner. They typically consist of a header, content, and an expand/collapse action.`} /> */}        
        <ToastContainer draggable={true} />
        {handlePopups()}
            
        </div>
    )
}

export default ExophoneList