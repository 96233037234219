import React, { useState, useEffect } from 'react'
import ApiActions from '../../actions/ApiActions';
import GSheetTemplateForm from '../common/GsheetTemplateForm'
import handleErrorMessage from '../../helper/handleErrorMessage'
import { ToastContainer, toast } from "react-toastify";
import FrappButton from "../utilities/FrappButton";

const GoogleSheetTemplate = (props) => {
    const dummyTemplate = {
        sheetId: '',
        outcomesFilter: [],
        isActive: false,
        isEdit: false
    }

    const [templates, setTemplates] = useState([dummyTemplate])
    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({})
    const [scriptHeaders, setScriptHeaders] = useState([])

    useEffect(() => {
        let id = props.match.params.id
        ApiActions.getTeleProject(id).then(resp => {
            setFormData({ ...resp })
            if (resp.gSheet) {
                const { sheetId, outcomesFilter, isActive } = resp.gSheet
                setTemplates([{
                    sheetId: `https://docs.google.com/spreadsheets/d/${sheetId}`, outcomesFilter: outcomesFilter, isActive,
                    isEdit: false
                }])
            }
        }).then(() => {
            ApiActions.getScript(id).then(resp => {
                let headers = []
                let first = resp && resp[0]
                if (first && first.script) {
                    first.script.map(item => {
                        if (item.data && item.data && item.data.form && item.data.form.length > 0) {
                            item.data.form.map((each) => {
                                headers.push(each.label)
                                if(each && each.options && each.options.length > 0){
                                    const otherOptionFound = each.options.find(({optionText}) => ['others', 'Others', 'other'].includes(optionText))
                                    if(otherOptionFound)
                                    headers.push(`${each.label} - Other`)
                                }
                        })

                        }
                    })
                }
                if (headers && headers.length > 0) {
                    setScriptHeaders(headers)
                }
            })
            }).catch(err => {
                console.error(err.response)
            }).finally(() =>
                setLoading(false)
            )
    }, [])

    const getFormData = (key, value) => {
        const [first] = templates
        const temp = { ...first }
        temp[key] = value
        temp.isEdit = true
        setTemplates([temp])
    }

    const saveSheet = () => {
        // Regular expression to capture the Google Sheet ID
        const googleSheetLinkPattern = /^https:\/\/docs\.google\.com\/spreadsheets\/d\/([a-zA-Z0-9-_]{40,})(\/.*|#gid=\d+)?$/;
        const match = templates && templates[0] && templates[0].sheetId.match(googleSheetLinkPattern);
        const sheetId = match && match[1]
        formData.gSheet = {
            isActive: templates[0].isActive,
            outcomesFilter: templates[0].outcomesFilter,
            sheetId: sheetId
        }

        ApiActions.updateTeleProject(formData, formData.id).then(resp => {
            toast('G-Sheet integration is saved')

            if (resp.gSheet) {
                const { sheetId, outcomesFilter, isActive } = resp.gSheet
                setTemplates([{
                    sheetId: `https://docs.google.com/spreadsheets/d/${sheetId}`, outcomesFilter: outcomesFilter, isActive,
                    isEdit: false
                }])
            }
        }).catch(err => {
            const { response: { data } } = err
            console.error(data)
            const msg = data && data[0] && data[0].message
            toast(handleErrorMessage(msg))
        })
    }

    if (loading == false) {
        return (
            <div className='templates_wrapper'>
                <div>
                    {
                        templates.map((item, idx) => <GSheetTemplateForm
                            item={item} idx={idx}
                            templatesCount={templates && templates.length > 0 ? templates.length - 1 : 0}
                            // outcomeTemplate={outcomeTemplate}
                            projectData={formData}
                            getFormData={getFormData}
                            scriptHeaders={scriptHeaders}
                            saveSheet={saveSheet}
                        />)
                    }
                </div>

                <div className="btn-wrapper">
                    <FrappButton type="submit" className="submit"
                        handler={() => props.history.push({
                            pathname: `/projects`,
                        })}
                    >Finish</FrappButton>
                    <FrappButton className="submit" id="new-btn-2"
                        handler={() => props.history.push({
                            pathname: `/projects`,
                        })}
                    > Skip</FrappButton>
                </div>
                <ToastContainer draggable={true} />
            </div>
        )
    } else {
        return (
            <div className="loader-main" id="loader-size">
                <img src={require("../../assets/images/loadernew.gif")} />
                <div className="text">
                    We'll be right back. Here: listen to some awesome songs meanwhile
                    <a href="https://open.spotify.com/playlist/37i9dQZF1DX3rxVfibe1L0" target="_blank" rel="noopener noreferrer"> Take me</a>
                </div>
            </div>
        )
    }

}

export default GoogleSheetTemplate