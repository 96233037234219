import React, { useContext, useState, useRef } from 'react'
import PanelGroup from 'react-bootstrap/lib/PanelGroup'
import Panel from 'react-bootstrap/lib/Panel'
import Dropdown from 'react-dropdown';
import cloneDeep from "lodash/cloneDeep";
import { ISContext } from './ISContext'
import ApiActions from '../../../actions/ApiActions';
import { ToastContainer, toast } from "react-toastify";
import FrappButton from '../../utilities/FrappButton';
import FutworkButton from '../../../uicomponents/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faXmark } from '@fortawesome/free-solid-svg-icons'
import constants from '../../../util/constants';
import AutoSuggestTextarea from './Textarea'

const Editor = (props) => {
	const { progressPercent, setScriptPublished, setPracticePublished, taskProps, setElements1, setPristine, showEdgeForm, setEdgeForm, setAllScripts, setAllScripts2, setProgressPercent, isSubmitTriggered, setSubmitTriggered, isTextToSpeechDone, setTextToSpeechDone, setScriptId, teleproject, outcomesWithFeedback, activeedge, outcomes, setEdge, scriptId, reactFlowInstance, crud, showCrud, heading, setHeading, formDataToSend, setFormDataToSend, removeElement, setRemovElement, currentNodeData, setCurrentNodeData, currentEdgeData, setCurrentEdgeData, setDirty, addNode, setUnSaved, setReactFlowInstance, activenode, nodeDragged, setNodeDragged, setNode, isDataPresent, setElements, teleprojectTitle, unSaved, scriptPublished, teleprojectdata, faqs, outcomeFlag, allScripts, allScripts2, elements, elements1,dataCollectionPractice, setDataCollectionPractice, setIsDataPresent, setActiveLang} = useContext(ISContext)
	const [showSuggestions, setShowSuggestions] = useState(false)
	const buttonRef = useRef('')
	const circularProg = useRef('')
	const checkComplete = useRef('')

	const [audioSubmit, setAudioSubmit] = useState(false)

	const progessStatusOfSubmit = () => {
		return <div style={{ display: 'flex', gap: '10px', alignItems: 'center', justifyContent: 'center' }}>
			<span>{progressPercent}</span>
			<div className="circle-loader" ref={circularProg}>
				<div className="checkmark draw" ref={checkComplete}></div>
			</div>
		</div>
	}

	const convertionAnimation = () => {
		return isSubmitTriggered ? <div>
			<div id="bars">
				{
					Array.from(Array(10)).map((each, i) => <div className="bar"></div>)
				}
			</div>
		</div> : null
	}

	const submitForm = async (e) => {
		e.preventDefault()
		// buttonRef.current.style.display = 'none'
		// commenting the setTextToSpeechDone flag as the IS practice feature is being deprecated from June 2024
		// setTextToSpeechDone(false)
		// setSubmitTriggered(true)
		props.removeDeletedElements()
		let result = props.updateElementPosition()

		const oldId = result[0].script.find(e => e.id == activenode.id)
		const oldEdgeId = result[0].script.find(e => e.id == activeedge.id)

		if (oldId) {
			// console.log('Node already present')
		} else {
			// console.log('New node to be added')
			const updatedElementsPosition = elements.map(({ id, position }) => {
				if (position) {
					let arr = [id, position]
					return arr
				}
			}).filter(Boolean)
			const entries = new Map(updatedElementsPosition);
			const obj = Object.fromEntries(entries);
			result.map(lan => {
				currentNodeData.map(current => {
					if (lan.scriptLanguage == current.name) {
						let ob = {
							id: current.nodeData.id,
							type: current.nodeData.type,
							data: current.nodeData.data,
							position: current.nodeData.position ?
							current.nodeData.position :
							{
								x: current.nodeData.xPos,
								y: current.nodeData.yPos
							},
						}
						if(current.nodeData.initialNode){
							ob.initialNode = current.nodeData.initialNode
						}
						lan.script = [...lan.script, ob]
						return lan
					}
				})

			})
		}

		if (oldEdgeId) {
			result.map(lan => {
				currentEdgeData.map(current => {
					if (lan.scriptLanguage == current.name) {
						const idx = lan.script.findIndex(e => e.id == current.nodeData.id)
						let data = current.nodeData.data
						current.nodeData = {...current.nodeData, data }
						lan.script[idx] = current.nodeData
						return lan
					}
				})
			})
		} else {
			// console.log('New edge to be added', current)
			result.map(lan => {
				currentEdgeData.map(current => {
					if (lan.scriptLanguage == current.name) {
						lan.script = [...lan.script, current.nodeData]
						return lan
					}
				})

			})
		}

		result.map(each => {
			let remainingElement = elements.filter(val => !each.script.find(arr1Obj => arr1Obj.id === val.id))
			each.script = [...each.script, ...remainingElement]
		})

		//check if the outcome is empty
		const checking = result && result.length > 0 && result[0].script.filter(each => {
			if((each.label == '' || each.label == 'label') || (each.data && (each.data.outcome == 'outcome' || each.data.label == ''))){
				return each
			}
		})

		if(checking && checking.length > 0){
			toast(`${checking.length} edges are not linked to the outcome or the edge label is not mentioned`)
			// setSubmitTriggered(false)
			return false
		}
		setSubmitTriggered(true)
		buttonRef.current.style.display = 'none'

		let dataToSend = {}

		const isScriptPublished = () => {
			return props.elementAddedOrDeleted()
		}

		const isPracticePublished = () => {
			return props.elementAddedOrDeleted()
		}

		function removeDuplicatesById(array) {
			const seenIds = new Set();
			const result = [];

			for (const obj of array) {
				if (!seenIds.has(obj.id)) {
				seenIds.add(obj.id);
				result.push(obj); // Add the first occurrence of the ID
				}
				// Subsequent occurrences are ignored automatically
			}

			return result;
		}

		if (scriptId) {
			dataToSend = {
				"teleprojectId": teleproject,
				"scriptPublished": isScriptPublished(),
				// commenting the practicePublished key as the IS practice feature is being deprecated from June 2024
				// "practicePublished": isPracticePublished(),
				"scripts": result.map((form, i) => {
					form.script = removeDuplicatesById(form.script)
					if (allScripts[i] && form.scriptLanguage == allScripts[i].scriptLanguage) {
						let x = {
							...form,
							scriptId: allScripts[i].id
						}
						return x
					} else {
						//if the script is a new one and doesnt have a script id
						return form
					}
				})
			}
		} else {
			dataToSend = {
				"teleprojectId": teleproject,
				"scriptPublished": isScriptPublished(),
				// commenting the practicePublished key as the IS practice feature is being deprecated from June 2024
				// "practicePublished": isPracticePublished(),
				"scripts": formDataToSend
			}
		}

		setRemovElement('')
		setNodeDragged('')

		let g;
		if (currentNodeData.length > 0) {
			g = dataToSend.scripts.map(r => {
				let node = r.script.filter(s => s.id == currentNodeData[0].nodeData.id)
				if(node[0] && node[0].data.heading == 'Ending'){
					node[0].type = 'output'
				}else if(node[0] && node[0].data.heading == 'Start'){
					node[0].type = 'input'
				}
				let x = {
					scriptNode: { ...node[0] },
					scriptLanguage: r.scriptLanguage,
					scriptId: r.scriptId,
				}
				return x
			})
		} else if (currentEdgeData.length > 0) {
			g = dataToSend.scripts.map(r => {
				let node = r.script.filter(s => s.id == currentEdgeData[0].nodeData.id)
				let x = {
					scriptNode: { ...node[0] },
					scriptLanguage: r.scriptLanguage,
					scriptId: r.scriptId,
				}
				return x
			})
		}

		if(currentNodeData.length > 0){
		let allNodesWithSameFlow  = dataToSend.scripts && dataToSend.scripts[0].script.filter(elem => {
			if(elem.data && elem.data.heading == currentNodeData[0].nodeData.data.heading){
				return elem
			}
		})
		let elementsWithInitialNodeFlag = allNodesWithSameFlow && allNodesWithSameFlow.filter(elem => elem.initialNode)
		
		if(elementsWithInitialNodeFlag.length > 1){
			// toast('More than one primary node with same flow')
			setProgressPercent('More than one primary node with same flow')
			setTimeout(() => {
				setSubmitTriggered(false)
				buttonRef.current.disabled = false
				buttonRef.current.style.display = 'block'
				setProgressPercent('')
			}, 2000)
			return false
		}
	}

		let dataToSendTextToSpeech = {
			nodes: [...g]
		}
		
		// commenting the entire logic to check if the text to speech api is triggered as it is being deprecated from June 2024
		// the logic to append the url for the text to speech audio is also being commented for the same reason
		// if(audioSubmit){
		// setProgressPercent('Audio uploading....')
		// const dataToSendWithAudioUrl = await ApiActions.googleTextToSpeech(dataToSendTextToSpeech)
		// 	.then(resp => {
		// 		setTextToSpeechDone(true)
		// 		let elementWithAudio = resp.data.map(({ scriptLanguage, scriptNode }) => {
		// 			const { id, data } = scriptNode
		// 			const { audio } = data
		// 			return {
		// 				scriptLanguage,
		// 				id,
		// 				audio
		// 			}
		// 		})

		// 		currentEdgeData.length > 0 ? currentEdgeData.map((current, i) => {
		// 			if (elementWithAudio[i].scriptLanguage == current.name) {
		// 				current.nodeData.data = {
		// 					...current.nodeData.data,
		// 					audio: elementWithAudio[i].audio
		// 				}
		// 				return current
		// 			}
		// 		}) : currentNodeData.map((current, i) => {
		// 			if (elementWithAudio[i].scriptLanguage == current.name) {
		// 				current.nodeData.data = {
		// 					...current.nodeData.data,
		// 					audio: elementWithAudio[i].audio
		// 				}
		// 				return current
		// 			}
		// 		})
		// 		setTextToSpeechDone(true)
		// 		dataToSend.scripts.map(({ script, scriptLanguage }, i) => {
		// 			if (elementWithAudio[i].scriptLanguage == scriptLanguage)
		// 				script.map(scr => {
		// 					if (scr.id == elementWithAudio[i].id) {
		// 						scr.data = {
		// 							...scr.data,
		// 							audio: elementWithAudio[i].audio
		// 						}
		// 						return scr
		// 					}
		// 				})
		// 		})
		// 		return dataToSend
		// 	})
		// 	.catch(err => {
		// 		console.error(err)
		// 		console.error(err.response)
		// 		setProgressPercent('Error while uploading the audio')
		// 		circularProg.current.style.display = 'none'

		// 		setTimeout(() => {
		// 			setSubmitTriggered(false)
		// 			buttonRef.current.disabled = false
		// 			buttonRef.current.style.display = 'block'
		// 			setProgressPercent('')
		// 			toast('Error while uploading audio')
		// 			// toast.error(err.response.data.msg ? err.response.data.msg : err.response.data ? err.response.data : `error`)
		// 		}, 3000)
		// 	})

		// 	if (dataToSendWithAudioUrl) {
		// 		setProgressPercent('Script creation/updation in progress....')
		// 		setRemovElement('')
		// 		setNodeDragged('')
		// 		createUpdateScript(dataToSendWithAudioUrl, 'audio needs resubmission')
		// 	}
		// }else{
		    // this logic would just save the script without checking for the audio url
			setProgressPercent('Script creation/updation in progress....')
			setRemovElement('')
			setNodeDragged('')
			createUpdateScript(dataToSend, 'audio does not need resubmission')
		// }
	}

	const updatePracticeAnswerForDataCollection = (apiBody) => {
		let tempDataCollection = cloneDeep(dataCollectionPractice)
		tempDataCollection.data.form.map(question => {
			if(!question.isPracticeAnswer){
				delete question.answerForPractice
			}
			delete question.isPracticeAnswer
			return question
		})
		apiBody.scripts.forEach(element => {
			element.script.map(item => {
				if(item.id == tempDataCollection.id){
					item.data.form = tempDataCollection.data.form
				}
				return item
			})
			return element
		});

	}
	const createUpdateScript = (apiBody, msg) => {
		if(dataCollectionPractice && dataCollectionPractice.data && dataCollectionPractice.data.form){
			updatePracticeAnswerForDataCollection(apiBody)
		}
		ApiActions.createScript(apiBody).then(resp => {
			if (circularProg && checkComplete) {
				setProgressPercent('Script creation/updation complete')
				circularProg.current.classList.toggle('load-complete')
				checkComplete.current.style.display = 'block'
			}

			setTimeout(() => {
				setSubmitTriggered(false)
				buttonRef.current.disabled = false
				buttonRef.current.style.display = 'block'
				setProgressPercent('')
			}, 2000)

			if (crud) {
				currentNodeData.map(current => {
					current.isEdited = true
					return current
				})
				// showCrud(!crud)
			} else if (showEdgeForm) {
				currentEdgeData.map(current => {
					current.isEdited = true
					return current
				})
				// setEdgeForm(!showEdgeForm)
			}
			const entries = new Map(teleprojectdata.languages.map(lang => {
				let arr = [lang.name, lang.default]
				return arr
			}).filter(Boolean));
			const createObjWithDefaultLang = Object.fromEntries(entries);
			//attach the defaultScript field to the result to identify the default language for the script
			resp.data.scripts.map(script => {
				script.defaultScript = createObjWithDefaultLang[script.scriptLanguage]
				return script
			})

			setAllScripts(resp.data.scripts)
			resp.data.scripts.map(elem => {
				if (elem.defaultScript) {
					setElements(elem.script)
					setElements1([...elem.script])
					setAllScripts2(elem.script)
					setScriptPublished(elem.scriptPublished)
					// commenting the setting of practicepublished flag as IS practice being deprecated from June 2024
					// if (elem.practicePublished) {
					// 	setPracticePublished(elem.scriptPublished)
					// } else {
					// 	setPracticePublished('')
					// }
					setScriptId(elem.id)
					// setElements(updatedScript)
					setUnSaved(false)
					setPristine();
					setAudioSubmit(false)
					if(elem.defaultScript){
					 setActiveLang(elem.scriptLanguage)
					}
					let getSourceNode = elem.script.find(elem => elem.id == dataCollectionPractice.id)
					if(getSourceNode){
						getSourceNode && getSourceNode.data && getSourceNode.data.form &&
						getSourceNode.data.form.map(item => {
							if(item.answerForPractice){
								item.isPracticeAnswer = true
							}else{
								item.isPracticeAnswer = false
							}
							return item
						})
						setDataCollectionPractice(getSourceNode)
					}
					if(isDataPresent){
						setIsDataPresent(false)
					}
				}
			})
		}).catch(err => {
			console.error(err)
			if (circularProg && checkComplete) {
				setProgressPercent('Error')
				// circularProg.current.classList.toggle('load-complete')
				// checkComplete.current.style.display = 'block'
				circularProg.current.style.display = 'none'
			}

			setTimeout(() => {
				setSubmitTriggered(false)
				buttonRef.current.disabled = false
				buttonRef.current.style.display = 'block'
				setProgressPercent('')
				toast.error(err.response.data.msg ? err.response.data.msg : err.response.data ? err.response.data : `error`)
			}, 3000)
			// toast(err.response && err.response.data ? err.response.data.msg : `error`)
		})
	}

	const checkForTextValidation = () => {
		if (currentNodeData.length > 0) {
			let isValid = currentNodeData.some(current => current.nodeData.data && current.nodeData.data.label && current.nodeData.data.label.trim() == 'Default' || current.nodeData.data && current.nodeData.data.label && current.nodeData.data.label.trim() == '' || current.nodeData.data && current.nodeData.data.label && current.nodeData.data.label.trim() == 'input')
			return isValid
		} else if (currentEdgeData.length > 0) {
			let isValid = currentEdgeData.some(current => current.nodeData.label.trim() == 'label' || current.nodeData.label.trim() == '')
			// const isTextPracticePresent = currentEdgeData.some(current => (current.nodeData.data && current.nodeData.data.label !== undefined && current.nodeData.data && current.nodeData.data.label !== '') ? false : true)
			return isValid 
			// && !isTextPracticePresent ? false : true
		}
	}

	const NodeEditor = () => {
		let addFlowToOutcome = allScripts[0]  && allScripts[0].scriptHeadings &&
		allScripts[0].scriptHeadings.map(each => {
			return {
				label: each,
				value: each
			}
		}) || [];
		const standardOutcomeTitle = constants.standardOutcomes.map(ot => ot.title)
		let defaultOutcomes = standardOutcomeTitle.map(defOutcome => {
			let result = !outcomes.includes(defOutcome)
			if(result){
			const present = outcomes.find(outcome => {
				return outcome == defOutcome
			})
			return present ? present : defOutcome
			}else{
			 return outcomes.find(outcome => {
				 if(outcome == defOutcome)
				 return outcome
			 })
			}
		})
		defaultOutcomes = defaultOutcomes.map(item => {
			return {
				label: `${item} Flow`,
				value: `${item} Flow`
			}
		})
		let headings = [
			{ label: "Start", value: "Start" },
			{ label: "Introduction", value: "Introduction" },
			{ label: "Main Pitch", value: "Main Pitch" },
			{ label: "Quick Pitch", value: "Quick Pitch" },
			...defaultOutcomes,
			...addFlowToOutcome,
			{ label: 'Closing', value: 'Closing' },
			{ label: 'Ending', value: 'Ending' }
		]

		const setHeadingData = (e, i, lan) => {
			let objCopy = cloneDeep(activenode)
			if (!objCopy.position) {
				let position = {
					x: 0,
					y: 0
				}
				position.x = activenode.xPos;
				position.y = activenode.yPos;
				objCopy["position"] = position
			} else {
				objCopy.position.x = activenode.xPos;
				objCopy.position.y = activenode.yPos;
			}
			currentNodeData.map(r => {
				r.nodeData.data.heading = e.value
				return r
			})

			let current = cloneDeep(currentNodeData)
			current.map(each => each.nodeData.data.heading = e.value)
			// current[i].nodeData = { ...objCopy }
			// current[i].nodeData.data.heading = e.value
			const newFormDataToSend = formDataToSend.map(e => {
				e.script.map(f => {
					if (f.id == current[0].nodeData.id) {
						f.data.heading = current[0].nodeData.data.heading 
						 // current[i].nodeData.data.heading
					}
				})
				return e
			})
			setFormDataToSend([...newFormDataToSend])
			setCurrentNodeData([...current])
			//update the heading on the node as soo as it is changed in the editor
			let updatedElements = elements.map(el => {
				if(el.id == current[0].nodeData.id){
					el.data.heading = e.value
					if(e.value == 'Ending'){
						el.type = 'output'
					}else if(e.value == 'Start'){
						el.type = 'input'
					}else{
						el.type = 'default'
					}
				}
				return el
			})
			setElements(updatedElements)
			// updateFormDataRealtime(lan, current[i], e.value, 'heading')
		}

		const updateFormDataRealtime = (lang, current, value, key) => {
			formDataToSend.map(form => {
				if (form.scriptLanguage == lang.name) {
					form.script.map(elem => {
						if (elem.id == current.nodeData.id) {
							elem.data[key] = value
							if(current.nodeData.initialNode){
								elem.initialNode = current.nodeData.initialNode
							}
							return elem
						}
					})
				}
			})
		}

		const newsetNodeLabel = (val, i, lan) => {
			let objCopy = cloneDeep(activenode)
			if (!objCopy.position) {
				let position = {
					x: 0,
					y: 0
				}
				position.x = activenode.xPos;
				position.y = activenode.yPos;
				objCopy["position"] = position
			} else {
				objCopy.position.x = activenode.xPos;
				objCopy.position.y = activenode.yPos;
			}

			let current = cloneDeep(currentNodeData)
			const isInitialFlagPresent = current[0].nodeData.initialNode
			// 	if(filtered.length !== current.length){
			current.filter((e, idx) => {
				if (idx === i) {
					e.isEdited = false
					return e
				}
			})
			// }
			current[i].nodeData = { ...objCopy }
			current[i].nodeData.data.label = val

			if (currentNodeData[i].nodeData.data && currentNodeData[i].nodeData.data.callerCueText) {
				current[i].nodeData.data.callerCueText = currentNodeData[i].nodeData.data.callerCueText
			}

			if(isInitialFlagPresent){
				current.map(node => {
					node.nodeData.initialNode = isInitialFlagPresent
					return node
				})
			}
			setCurrentNodeData([...current])
			setCurrentEdgeData([])
			updateFormDataRealtime(lan, current[i], val, 'label')
		}

		const setCallerCueText = (e, i, lang) => {
			let objCopy = cloneDeep(activenode)
			if (!objCopy.position) {
				let position = {
					x: 0,
					y: 0
				}
				position.x = activenode.xPos;
				position.y = activenode.yPos;
				objCopy["position"] = position
			} else {
				objCopy.position.x = activenode.xPos;
				objCopy.position.y = activenode.yPos;
			}
			let current = cloneDeep(currentNodeData)
			const isInitialFlagPresent = current[0].nodeData.initialNode
			current[i].nodeData = { ...objCopy }
			current[i].nodeData.data.callerCueText = e
			if (currentNodeData[i].nodeData.data && currentNodeData[i].nodeData.data.label) {
				current[i].nodeData.data.label = currentNodeData[i].nodeData.data.label
			}
			if(isInitialFlagPresent){
				current.map(node => {
					node.nodeData.initialNode = isInitialFlagPresent
					return node
				})
			}
			setCurrentNodeData([...current])
			setCurrentEdgeData([])
			updateFormDataRealtime(lang, current[i], e, 'callerCueText')
		}

		const nodeTextForPractice = (lang) => {
			return (
				isTextToSpeechDone ? <div className='node-text-practice'>
					<audio controls src={lang.nodeData.data.audio}></audio>
				</div> : !isTextToSpeechDone && lang.nodeData.data.audio ?
					<div className='node-text-practice'>
						<audio controls src={lang.nodeData.data.audio}></audio>
					</div> : null
			)
		}

		const onClick = (e, i) => {
			let p = currentNodeData[i].nodeData.data.label
			let r = p + e.currentTarget.innerText
			//remove last word from string
			let lw1 = r.replace(/[\W]*\S+[\W]*$/, '')
			currentNodeData[i].nodeData.data.label = `${lw1} ` + e.currentTarget.innerText
			//pass the update text from the node to formData on the click of the suggestion
			formDataToSend.map(form => {
				if (form.scriptLanguage == currentNodeData[i].name) {
					form.script.map(each => {
						if (each.id == currentNodeData[i].nodeData.id) {
							each.data.label = currentNodeData[i].nodeData.data.label
						}
					})
				}
			})
			setCurrentNodeData([...currentNodeData])
			setCurrentEdgeData([])
			setShowSuggestions(false)
		}

		let suggestionsListComponent = (i) => {
			return showSuggestions ? <ul className='suggestions-1'>
				{taskProps.map((suggestion, index) => {
					return (
						<li key={suggestion.name} onClick={(e) => onClick(e, i)}>
							{suggestion.name}
						</li>
					)
				})}
			</ul> : null
		}

		const markNodeAsInitial = (e) => {
			let current = cloneDeep(currentNodeData)
			const isChecked = e.target.checked
			if(isChecked){
				current.map(node => {
					node.nodeData.initialNode = e.target.checked
					return node
				})
			}
			const isInitialFlagPresent = current[0].nodeData.initialNode
			if(isInitialFlagPresent){
				current.map(node => {
					node.nodeData.initialNode = e.target.checked
					return node
				})
			}

			setCurrentNodeData([...current])



			formDataToSend.map(form => {
					form.script.map(elem => {
						if (elem.id == current[0].nodeData.id) {
							if(!isChecked){
								delete elem.initialNode
							}else{
								elem.initialNode = current[0].nodeData.initialNode
							}
							return elem
						}
					})
			})
		}

		let getTheSavedNode = allScripts2.length > 0 ? allScripts2.find(sc => sc.id == activenode.id) : ''
		let nodeSavedInDB = getTheSavedNode
		// let showDCIfExist = currentNodeData.find(item => item.nodeData.data.form)
		// let showTAIfExist = currentNodeData.find(item => item.nodeData.data.form)
		
		return (
			<div className="crud-form">
				{/* <button id = "x">X</button> */}
				{/* <div onClick={() => showCrud(false)} className='close-sec'>Close</div> */}
				<div className='header-section'>
				<span className='heading'>Node Editor</span>
				<FontAwesomeIcon icon={faXmark} className='close-icon' onClick={() => showCrud(false)}/>
				</div>
				<div className='border-outline'>
				<div className="title">Title</div>
				<Dropdown
				className="script-dropdown"
				options={headings}
				onChange={(e) => setHeadingData(e, null, null)}
				value={activenode.data.heading || "Heading"}
				placeholder="Select an option" />
				<label style={{padding: '0 0 0 3.5%'}}><input type="checkbox" checked={currentNodeData[0].nodeData.initialNode ? currentNodeData[0].nodeData.initialNode : false} onChange={(e) => markNodeAsInitial(e)} /> <span>Mark {activenode.data.heading} as initial</span></label>
				
				<FutworkButton 
					buttonSize='medium w-100'
					onClick={()=> {props.setShowAddLabel(true);props.formQuestion(currentNodeData)}}
				>
				+ Create new label
				</FutworkButton>
				</div>
			
				<div className="in-form">
				<div className='border-outline'>
					<PanelGroup accordion id="accordion-example">
						{
							currentNodeData && currentNodeData.length > 0 && currentNodeData.map((lang, i) => {
								return (
									<Panel eventKey={i + 1} key={i + 1}>
										<Panel.Heading>
											<Panel.Title toggle><div className="title"> Text ({lang.name}) {!lang.isEdited ? <span className='is-edited'>Edited</span> : null}</div></Panel.Title>
										</Panel.Heading>
										<Panel.Body collapsible>
											<div style={{ margin: '0 0 8% 0' }}>
												<div className="autosuggest-script" id={lang.name}>
												</div>
												{/* <textarea rows="4" style={{ width: "100%" }}
													// value={activenode.data.label||""}
													value={lang.nodeData ? lang.nodeData.data.label : activenode.data.label}
													onKeyPress={(e) => {
														if (e.key == '@')
															setShowSuggestions(true)
													}}
													required
													name={`data-${i + 1}`}
													onChange={(e) => {
														// setAudioSubmit(true)
														newsetNodeLabel(e.target.value, i, lang)
														// setNodeLabel(e.target.value)
													}}
												/> */}
												<AutoSuggestTextarea value={lang.nodeData ? lang.nodeData.data.label : activenode.data.label} required={true}
												name={`data-${i + 1}`}
												suggestionslist={taskProps}
												index={i}
												lang={lang}
												newsetNodeLabel={newsetNodeLabel}
												/>
												{/* {suggestionsListComponent(i)} */}
												{/* commenting the nodeTextForPractice function as IS practice is being deprecated from June 2024 */}
												{/* {nodeTextForPractice(lang)} */}
												<div className="title-cue">Caller Cue </div>
												<textarea style={{ width: "100%" }}
													value={lang.nodeData.data && lang.nodeData.data.callerCueText ? lang.nodeData.data.callerCueText : ''}
													onChange={(e) => setCallerCueText(e.target.value, i, lang)}
												/>
											</div>
										</Panel.Body>
									</Panel>
								)
							})
						}
					</PanelGroup>
				</div>
				
				{nodeSavedInDB && <div className='border-outline'>
				<div className="title">Actions</div>
					{/* {
						nodeSavedInDB ? <div className='dc-section'>
							<span className='dc-form-button' onClick={() => {
								props.setDCModal(true)
								props.formQuestion(currentNodeData)
							}}>+ Data collection Form</span>
						</div> : null
					}
					{
						nodeSavedInDB ? <div className='live-action-section'>
						<FrappButton className="live-action-btn"
						handler={() => {
							props.setShowActionTrigger(true)
							props.formQuestion(currentNodeData)
						}}
						><img src={require('../../../assets/images/Icons_IS_trigger_white.svg')} />{'Trigger Action'}</FrappButton>
						</div> : null
					} */}
					{nodeSavedInDB && <FutworkButton 
					buttonSize='medium w-100'
					onClick={()=>{props.setDCModal(true);props.formQuestion(currentNodeData)}}
					>
					+ Data Collection Form
					</FutworkButton>}
					{nodeSavedInDB && <FutworkButton 
					buttonStyle='gradient-1--solid' 
					buttonSize='medium w-100'
					onClick={()=>{props.setShowActionTrigger(true);props.formQuestion(currentNodeData)}}
					>
					<img src={require('../../../assets/images/Icons_IS_trigger_white.svg')} /> Trigger Action
					</FutworkButton>}
				</div>}
				{!isSubmitTriggered ? <div ref={buttonRef}>
				<FutworkButton buttonSize='medium w-100' buttonStyle='success--solid'
				disabled={checkForTextValidation()}
				onClick={submitForm}
				>SUBMIT</FutworkButton>
				</div> : progessStatusOfSubmit()}
					{/* {!isSubmitTriggered ? <button ref={buttonRef} disabled={checkForTextValidation()} onClick={submitForm}> Submit</button> : progessStatusOfSubmit()} */}
					{/* <hr /> */}
				</div>
			</div>
		)
	}

	const EdgeEditor = () => {
		const checkForm = (o1) => {
			//Form shouldnt exist here
			let a = elements.filter(el => {
				return el.id === activeedge.source
			})
			elements.map(elm => {
				if (elm.id === a[0].id) {
					delete elm.data.form;
				}
			})
			setElements(elements)
		}
	
		const setEdgeOutcome = (e, i, lang) => {
			let objCopy = cloneDeep(activeedge)
			objCopy.data.outcome = e.value;
			outcomesWithFeedback.map(o1 => {
				if (e.value === o1.title)
					addFormToNode()

			})
			let arrCheck = outcomesWithFeedback.filter(ol => {
				return ol.title === e.value
			})
			if (arrCheck.length == 0) {
				checkForm()
			}
			setEdge(
				objCopy
			)

			currentEdgeData.map(edge => {
				edge.nodeData.data.outcome = e.value
				return edge
			})

			formDataToSend.map(scr => {
				scr.script.map((edge, i) => {
					if (edge.id == objCopy.id) {
						scr.script[i].data.outcome = objCopy.data.outcome
					}
				})
			})
			let current = cloneDeep(currentEdgeData)
			let updateCurrent = current.map((lang, i) => {
				lang.nodeData.label = e.value
				return lang
			})
			setCurrentEdgeData([...updateCurrent])
			let result = props.updateElementPosition()
			result.map(lan => {
				updateCurrent.map(current => {
					if (lan.scriptLanguage == current.name) {
						const idx = lan.script.findIndex(e => e.id == current.nodeData.id)
						let data = current.nodeData.data
						current.nodeData = {...current.nodeData, data }
						lan.script[idx] = current.nodeData
						return lan
					}
				})
			})
			setCurrentNodeData([])
		};

		const addFormToNode = () => {
			let form = [];
			let a = elements.filter(el => {
				return el.id === activeedge.source
			})
			elements.map(el2 => {
				if (el2.id === a[0].id) {
					el2.data.form = form;
				}
			})
			setElements(elements)
		}

		const setEdgeLabel = (val, i, lang) => {
			let objCopy = cloneDeep(activeedge)
			let current = cloneDeep(currentEdgeData)
			current[i].nodeData = { ...objCopy }
			current[i].nodeData.label = val
			if (current[i].nodeData.data) {
			} else {
				let data = {
					label: ''
				}
				current[i].nodeData = { ...current[i].nodeData }
			}
			if (currentEdgeData[i].nodeData.data && currentEdgeData[i].nodeData.data.label) {
				if(current[i] && current[i].data){
					current[i].nodeData.data.label = currentEdgeData[i].nodeData.data.label
					current[i].nodeData.data.audio = lang.nodeData.data.audio
				}
			}

			current.filter((e, idx) => {
				if (idx === i) {
					e.isEdited = false
					return e
				}
			})

			setCurrentEdgeData([...current])
			setCurrentNodeData([])
			// updateFormDataRealtime(lang,current[i],val,'label')
			formDataToSend.map(form => {
				if (form.scriptLanguage == lang.name) {
					form.script.map(elem => {
						if (elem.id == current[i].nodeData.id) {
							// elem.data.outcome = current[i].nodeData.data.outcome
							elem.label = val
							return elem
						}
					})
				}
			})
		}

		{/* commenting the below function as the IS Practice function as it is being deprecated from June 2024 */}
		// const setTextForPractice = (val, i, lang) => {
		// 	let objCopy = cloneDeep(activeedge)
		// 	let current = cloneDeep(currentEdgeData)
		// 	current[i].nodeData = { ...objCopy }


		// 	if (current[i].nodeData.data) {
		// 		current[i].nodeData.data.label = val
		// 		if (current[i].nodeData.data.audio) {
		// 			current[i].nodeData.data.audio = lang.nodeData.data.audio
		// 		}
		// 	} else {
		// 		let data = {
		// 			label: val,
		// 			audio: lang.nodeData.data && lang.nodeData.data.audio ? lang.nodeData.data.audio : ''
		// 		}
		// 		current[i].nodeData = { ...current[i].nodeData, data }
		// 	}
		// 	if (currentEdgeData[i].nodeData.label) {
		// 		current[i].nodeData.label = currentEdgeData[i].nodeData.label
		// 	}
		// 	setCurrentEdgeData([...current])
		// 	setCurrentNodeData([])

		// 	formDataToSend.map(form => {
		// 		if (form.scriptLanguage == lang.name) {
		// 			form.script.map(elem => {
		// 				if (elem.id == current[i].nodeData.id) {
		// 					if (elem.data) {
		// 						elem.data.label = current[i].nodeData.data.label
		// 					} else {
		// 						let data = {
		// 							label: current[i].nodeData.data.label
		// 						}
		// 						elem = { ...elem, data }
		// 					}
		// 					return elem
		// 				}
		// 			})
		// 		}
		// 	})
		// }

		// const textForPractice = (i, lang) => {
		// 	return (
		// 		<div className='edge-text-practice'>
		// 			<div className='title'>Customer’s verbal response (Interactive practice)</div>
		// 			{/* <textarea value={lang.nodeData && lang.nodeData.data && lang.nodeData.data.label ?  lang.nodeData.data.label : 'label'} onChange={(e) => {
		// 				setAudioSubmit(true)
		// 				setTextForPractice(e.target.value, i, lang)
		// 			}} /> */}
		// 			{isTextToSpeechDone ?
		// 				<audio controls src={lang.nodeData.data.audio}></audio>
		// 				: !isTextToSpeechDone && lang.nodeData.data && lang.nodeData.data.audio ?
		// 					<audio controls src={lang.nodeData.data.audio}></audio>
		// 					: null
		// 			}
		// 		</div>
		// 	)
		// }

		const isOutcome = [...currentEdgeData].map(lang => lang.nodeData.data ? lang.nodeData.data.outcome : '')[0]

		const handleChange = (e, idx) => {
			let temp = dataCollectionPractice
			if(!e){
				// delete temp.data.form[idx].answerForPractice
				temp.data.form[idx].isPracticeAnswer = e
			}else{
				temp.data.form[idx].isPracticeAnswer = e
			}
			setDataCollectionPractice({...temp})
		}

		const changePracticeAnswer = (e, idx) => {
			let temp = dataCollectionPractice
			temp.data.form[idx].answerForPractice = e
			setDataCollectionPractice({...temp})
		}

		const changeOption = (idx, id) => {
			let temp = dataCollectionPractice
			temp.data.form[idx].answerForPractice = temp.data.form[idx].options[id].optionText
			setDataCollectionPractice({...temp})
		}

		const changeMultiSelectOption = (idx, id) => {
			let temp = dataCollectionPractice
			if(temp.data.form[idx].answerForPractice){
				if(dataCollectionPractice.data.form[idx].answerForPractice.split(";").includes(temp.data.form[idx].options[id].optionText)){
					let filteredAnswer = dataCollectionPractice.data.form[idx].answerForPractice.split(";").filter(item => item !== temp.data.form[idx].options[id].optionText)
					temp.data.form[idx].answerForPractice = filteredAnswer.join(';')
				}else{
				temp.data.form[idx].answerForPractice = `${temp.data.form[idx].answerForPractice};${temp.data.form[idx].options[id].optionText}`
				}
			}else{
				temp.data.form[idx].answerForPractice = temp.data.form[idx].options[id].optionText
			}
			setDataCollectionPractice({...temp})
		}

		return (
			<div className="crud-form">
				<div className='header-section'>
				<span className='heading'>Edge Editor</span>
				<FontAwesomeIcon icon={faXmark} className='close-icon' onClick={() => setEdgeForm(false)}/>
				</div>
				{isOutcome ? <div>
				<div style={{padding: '0 15px'}}>Outcome</div>
				<Dropdown
				className="script-dropdown"
				options={outcomes}
				onChange={(e) => setEdgeOutcome(e, null, null)}
				value={isOutcome || "Choose Outcome"}
				placeholder="Select an option" />

				</div> : null}
				<div className="in-form">
				<div className='border-outline'>
					<PanelGroup accordion id="accordion-example">
						{
							currentEdgeData && currentEdgeData.length > 0 && currentEdgeData.map((lang, i) => {
								return (
									<Panel eventKey={i + 1} key={i + 1}>
										<Panel.Heading>
											<Panel.Title toggle><div className="title"> Edge ({lang.name}) {!lang.isEdited ? <span className='is-edited'>Edited</span> : null}</div></Panel.Title>
										</Panel.Heading>
										<Panel.Body collapsible>
											{/* {lang.nodeData.data && lang.nodeData.data.outcome ?
												<div>
													<div>Outcome</div>
													<Dropdown
														className="script-dropdown"
														options={outcomes}
														onChange={(e) => setEdgeOutcome(e, i, lang)}
														value={lang.nodeData.data.outcome || "Choose Outcome"}
														placeholder="Select an option" />

												</div> : null} */}
											<div>Label</div>
											<textarea style={{ width: "100%" }} value={lang.nodeData.label} onChange={(e) => setEdgeLabel(e.target.value, i, lang)} 
											// disabled={lang.nodeData && lang.nodeData.data && lang.nodeData.data.outcome ? true : false}
											/>
											{/* commenting the textForPractice function as the IS practice is being deprecated from June 2024 */}
											{/* {textForPractice(i, lang)} */}
										</Panel.Body>
									</Panel>
								)
							})
						}
					</PanelGroup>
					{/* {!isSubmitTriggered ? <button ref={buttonRef} disabled={checkForTextValidation()} onClick={submitForm}> Submit</button> : progessStatusOfSubmit()} */}
				</div>
				{dataCollectionPractice && dataCollectionPractice.data && dataCollectionPractice.data.form && 
				<div className='border-outline'>
					<PanelGroup accordion id="accordion-example">
									<Panel eventKey={1} key={1}>
										<Panel.Heading>
											<Panel.Title toggle>
												<div className="title">Data Collection: Expected response for IS practice</div>
											</Panel.Title>
										</Panel.Heading>
										<Panel.Body collapsible style={{maxHeight: '40vh', overflowY: 'auto', padding: '0 0'}}>
										<div>
										{
											dataCollectionPractice.data.form.map((question, idx) => {
								
												return <div className='is-practice-response'>
												<div className='dc'>
												<span className='question'>Q: {question.label}</span>
												<label class="switch">
												<input type="checkbox"
												id={idx}
												checked={question.isPracticeAnswer}
												onChange={(e) => handleChange(e.target.checked,idx)}
												/>
												<span class="slider round"></span>
												</label>
									
												</div>
												{question.isPracticeAnswer && question.inputType === 'text' && <input type='text' style={{width: '100%', margin: '4% 0'}} value={question.answerForPractice}
												onChange={(e)=>changePracticeAnswer(e.target.value, idx)}/>}
												{question.isPracticeAnswer && question.inputType === 'date-time' && <input type='text' style={{width: '100%', margin: '4% 0'}} value={question.answerForPractice}
												onChange={(e)=>changePracticeAnswer(e.target.value, idx)}/>}
												{question.isPracticeAnswer && question.inputType === 'singleselect' && <div key={idx}>
													{
														question.options && question.options.map((op, id) => {
															return <div key={id} style={{border: '1px solid #888888', margin: '2% 0', height: '35px', borderRadius: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2%'}}>
																{op.optionText}
																<div class="checkbox">
																<label>
																<input id="item1" type="checkbox" 
																checked={op.optionText == question.answerForPractice}
																onChange={()=>changeOption(idx, id)}/>
																</label>
																</div>
															</div>
														})
													}
												</div>}
												{question.isPracticeAnswer && question.inputType === 'multiselect' && <div key={idx}>
													{
														question.options.map((op, id) => {
															return <div key={id} style={{border: '1px solid #888888', margin: '2% 0', height: '35px', borderRadius: '4px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2%'}}>
																{op.optionText}
																<div class="checkbox">
																<label>
																<input id={`item1${id}`} type="checkbox" 
																checked={question.answerForPractice ? question.answerForPractice.split(';').includes(op.optionText) : false}
																onChange={()=>changeMultiSelectOption(idx, id)}/>
																</label>
																</div>
															</div>
														})
													}
												</div>}
												</div>
											})
										}
										</div>
										</Panel.Body>
									</Panel>
					</PanelGroup>
				</div>}
				{
					!isSubmitTriggered ?<div ref={buttonRef}>
					<FutworkButton buttonSize='medium w-100' buttonStyle='success--solid'
					disabled={checkForTextValidation()}
					onClick={submitForm}
					>SUBMIT</FutworkButton>
					</div>
					:
					progessStatusOfSubmit()
				}
				</div>
			</div>
		)
	}
	return props.nodeEditor ? NodeEditor() : EdgeEditor()
}

export default Editor