import React, { useState, createContext, useEffect, useContext } from 'react'
import ApiActions from '../../../actions/ApiActions';
import UnSavedWarning from '../Tools/UnsavedWarning';
import { ToastContainer, toast } from "react-toastify";

var initialElements = [
	{
		id: "1",
		type: "input",
		data: {
			label: "Hi, I am ----- calling from --- ",
			"heading": "Start"
		},
		position: { x: 0, y: 0 }
	},

	{
		id: "6",
		data: { label: "Thank you for your time,have a nice day  ", "heading": "Ending" },
		position: { x: 0, y: 100 },
		type: "output"
	}

]

const dynamicProps = [
	{
		name: '@name'
	},
	{
		name: '@mobile'
	},
	{
		name: '@email'
	}

]

export const ISContext = createContext();

export const ISProvider = props => {
	const [elements, setElements] = useState(initialElements);
	const [elements1, setElements1] = useState([]);
	const [teleprojectdata, setProjectData] = useState({})
	const [allScripts, setAllScripts] = useState('')
	const [allScripts2, setAllScripts2] = useState('')
	const [isLoading, setIsLoading] = useState(true)
	const [teleproject, setProject] = useState({})
	const [faqs, setFaqs] = useState([
		{
			"question": "",
			"answer": ""
		}
	])
	const [isFaq, setIsFaq] = useState([])
	const [teleprojectTitle, setProjectTitle] = useState({})
	const [unSaved, setUnSaved] = useState(null);
	const [scriptPublished, setScriptPublished] = useState("")
	const [practicePublished, setPracticePublished] = useState("")
	const [outcomeFlag, setOutcomeTrue] = useState(false)
	const [isDataPresent, setIsDataPresent] = useState(false)
	const [reactFlowInstance, setReactFlowInstance] = useState(null);
	const [activenode, setNode] = useState({})
	const [activeedge, setEdge] = useState({})
	const [nodeDragged, setNodeDragged] = useState('')
	const [Prompt, setDirty, setPristine] = UnSavedWarning();
	const [formDataToSend, setFormDataToSend] = useState([])
	const [currentNodeData, setCurrentNodeData] = useState([])
	const [currentEdgeData, setCurrentEdgeData] = useState([])
	const [removeElement, setRemovElement] = useState('')
	const [crud, showCrud] = useState(false)
	const [showEdgeForm, setEdgeForm] = useState(false)
	const [heading, setHeading] = useState("")
	const [scriptId, setScriptId] = useState("")
	const [outcomes, setOutcomes] = useState([])
	const [scriptPreview, setScriptPreview] = useState([])
	const [showLinkShareModal, setShowLinkShareModal] = useState(false)
	const [showPublishModal, setShowPublishModal] = useState(false)
	const [isLinkCopied, setIsLinkCopied] = useState(false)
	const [outcomesWithFeedback, setOutcomesWithFeedback] = useState([])
	const [dcOutcomes, setDCOutcomes] = useState([])
	const [isTextToSpeechDone, setTextToSpeechDone] = useState(false)
	const [isSubmitTriggered, setSubmitTriggered] = useState(false)
	const [progressPercent, setProgressPercent] = useState('')
	const [scriptDocLink, setScriptDocLink] = useState('')
	const [msgStatus, setMsgStatus] = useState({
		script: {
			status: '',
			msg: ''
		},
		practice: {
			status: '',
			msg: ''
		}
	})
	const [dataForFaq, setFaqApi] = useState({})
	const [taskProps, setTaskProps] = useState([]);
	const [validationArray, setValidationArray] = useState([])
	const [dataCollectionPractice, setDataCollectionPractice] = useState('')
	const [activeLang, setActiveLang] = useState('')
	const [faqActiveLanguage, setActiveFaqLanguage] = useState('')

	useEffect(() => {
		let languages = ''
		let history = window.location.href.split('/').reverse()[0]
		if (history) {
			ApiActions.getTeleProject(history)
				.then(resp => {
					setIsLoading(false)
					setProject(resp.id)
					setProjectData(resp)
					languages = resp.languages
					const [first] = languages
					setActiveFaqLanguage(first.name)
					if (resp.faqs && resp.faqs.length > 0) {
						setFaqs(resp.faqs)
						setIsFaq(resp.faqs)
					} else
						setFaqs([
							{
								"question": "",
								"answer": ""
							}
						])
					setProjectTitle(resp.title)
					let previewLink = ''
					resp.scriptDocuments && resp.scriptDocuments.forEach(item => {
						const url = new URL(item.url);
						if(url.host === 'business.futwork.com'){
							previewLink = item.url
						}  
					})
					setScriptDocLink(previewLink)
					let outcomeTemp = [];
					let outcomeTempFeedback = [];
					resp && resp.outcomes && resp.outcomes.map(outcome => {
						outcomeTemp.push(outcome.title)
						setOutcomes(outcomeTemp)
						if (outcome.additionalFeedback && outcome.additionalFeedback.length > 0) {

							outcomeTempFeedback.push(outcome)
						}
						setOutcomesWithFeedback(outcomeTempFeedback)
					})

					setDCOutcomes(
						resp.outcomes.map((item) => {
							if(item && item.additionalFeedback && item.additionalFeedback.length > 0) {
								return {
									...item, 
									additionalFeedback: item.additionalFeedback.map((item2) => {
										if(item2.questionType === "text" && item2.validationType && item2.validationType.includes("custom")) {
											if(item2.questionType === "text" && item2.customLength < 1) {
												
												return { ...item2, isCustom: true, customLength: 0 }
											}else{
												return { ...item2, isCustom: true, customLength: item2.customLength }
											}
										}else{
											return { ...item2, isCustom: false }
										}
									})
								}
							}else{
								return item
							}
						})
					)

					if (resp.taskProps) {
						let taskPropsTemp = [];
						resp && resp.taskProps.map((i) => {
							i.name = '@' + i.name
							taskPropsTemp.push(i)
						})
						setTaskProps(taskPropsTemp)
					} else {
						setTaskProps(dynamicProps)
					}

					ApiActions.getScript(history).then(res => {
						if (res.length <= 0){
						 setIsDataPresent(true)
						 const [first] = languages
						 let defaultLang = languages.filter(lang => lang.default)
						 if(defaultLang && defaultLang.length > 0){
							setActiveLang(defaultLang[0].name)
						 }else if(first && first.name){
							setActiveLang(first.name)
						 }
						}
						const getScriptForOnlyLanguagesPresent = res.filter((script, i) => {
							return languages.some(lang => lang.name == script.scriptLanguage)
						})
						setAllScripts(getScriptForOnlyLanguagesPresent)				
						res.map(scr => {
							//check for the default language and load the script in flowchart
							if (scr.defaultScript) {
								setActiveLang(scr.scriptLanguage)
								let script = scr
								if (script)
								setScriptPreview(script.script)
								setElements(scr.script)
								setElements1([...scr.script])
								setAllScripts2(scr.script)
								setScriptPublished(scr.scriptPublished)
								if (scr.practicePublished) {
									setPracticePublished(scr.practicePublished)
								}
								// AutoPosition(scr.script)
								setScriptId(scr.id)
							}
						})

						setScriptId(res && res[0] && res[0].id)
					})
				}).catch(err => { console.error(err) })

			ApiActions.getDataValidationArray().then((response) => {
				setValidationArray(response[0].validationType)
			})
		}
	}, [])

	const publishTheScript = async (scriptPublished, practicePublished) => {
		let dataToSend = {}
		function removeDuplicatesById(array) {
			const seenIds = new Set();
			const result = [];

			for (const obj of array) {
				if (!seenIds.has(obj.id)) {
				seenIds.add(obj.id);
				result.push(obj); // Add the first occurrence of the ID
				}
				// Subsequent occurrences are ignored automatically
			}

			return result;
		}

		if (scriptId) {
			dataToSend = {
				"teleprojectId": teleproject,
				"scriptPublished": scriptPublished,
				// commenting the practicePublished key as the IS practice feature is being deprecated from June 2024
				// "practicePublished": practicePublished,
				"scripts": formDataToSend.map((form, i) => {
					form.script = removeDuplicatesById(form.script)
					if (allScripts[i] && form.scriptLanguage == allScripts[i].scriptLanguage) {
						let x = {
							...form,
							scriptId: allScripts[i].id
						}
						return x
					} else {
						//if the script is a new one and doesnt have a script id
						return form
					}
				})
			}
		} else {
			dataToSend = {
				"teleprojectId": teleproject,
				"scriptPublished": scriptPublished,
				// commenting the practicePublished key as the IS practice feature is being deprecated from June 2024
				// "practicePublished": practicePublished,
				"scripts": formDataToSend
			}
		}
		let result = await ApiActions.createScript(dataToSend).then(resp => {
			// toast("Published")
			let updatedScript = resp && resp.data && resp.data.scripts[0].script;
			let id = resp && resp.data && resp.data.scripts[0].id;
			setScriptPublished(resp.data.scripts[0].scriptPublished)
			// setScriptId(id)
			setElements(updatedScript)
			setUnSaved(false)
			setPristine();
			if (resp.data.scripts[0].scriptPublished) {
				msgStatus.script.status = 'success'
				msgStatus.script.msg = 'Script Published'
			} else {
				msgStatus.script.status = 'failed'
				msgStatus.script.msg = 'Script is not Published'
			}
			// commenting the logic to add the message of audio being uploaded as the IS practice feature is being deprecated from June 2024
			// if (resp.data.scripts[0] && resp.data.scripts[0].practicePublished) {
			// 	msgStatus.practice.status = 'success'
			// 	msgStatus.practice.msg = 'Practice Published'
			// 	setPracticePublished(true)
			// } else if(!resp.data.scripts[0].practicePublished && resp.data.error) {
			// 	msgStatus.practice.status = 'failed'
			// 	msgStatus.practice.msg = 'Practice publish failed. Check if audios are uploaded for all the nodes & edges'
			// 	setPracticePublished(false)
			// }
			// if(practicePublished && resp.data.error){
			// 	msgStatus.practice.status = 'failed'
			// 	msgStatus.practice.msg = resp.data.error
			// 	setPracticePublished(false)
			// }else if(practicePublished){
			// 	msgStatus.practice.status = 'success'
			// 	msgStatus.practice.msg = 'Practice Published'
			// 	setPracticePublished(true)
			// }
			setMsgStatus({ ...msgStatus })
			return msgStatus
		}).catch(err => {
			// toast("error")
			msgStatus.script.status = 'failed'
			msgStatus.script.msg = err.response.data.msg ? err.response.data.msg : 'Error while publishing the script/practice'
			setMsgStatus({ ...msgStatus })
			return msgStatus
		})
		return result
	}

	return (
		<ISContext.Provider value={{ validationArray, dcOutcomes, setDCOutcomes, practicePublished, setPracticePublished, setScriptPublished, msgStatus, taskProps, dataForFaq, setFaqs, setFaqApi, isFaq, scriptDocLink, setPristine, isLoading, setMsgStatus, publishTheScript, showPublishModal, setShowPublishModal, isLinkCopied, setIsLinkCopied, showLinkShareModal, setShowLinkShareModal, scriptPreview, setScriptPreview, progressPercent, setProgressPercent, isTextToSpeechDone, setTextToSpeechDone, isSubmitTriggered, setSubmitTriggered, setScriptId, outcomesWithFeedback, outcomes, showEdgeForm, setEdgeForm, teleproject, setProject, activeedge, setEdge, scriptId, crud, showCrud, heading, setHeading, setUnSaved, formDataToSend, setFormDataToSend, removeElement, setRemovElement, currentNodeData, setCurrentNodeData, currentEdgeData, setCurrentEdgeData, setDirty, nodeDragged, setNodeDragged, reactFlowInstance, setReactFlowInstance, activenode, setNode, isDataPresent, setIsDataPresent, teleprojectTitle, unSaved, scriptPublished, teleprojectdata, faqs, setOutcomeTrue, outcomeFlag, allScripts, setAllScripts, allScripts2, setAllScripts2, elements, setElements, elements1, setElements1, dataCollectionPractice, setDataCollectionPractice,
		activeLang, setActiveLang, faqActiveLanguage, setActiveFaqLanguage }}>
			{props.children}
		</ISContext.Provider>
	)
}

export function useAPI() {
	const context = useContext(ISContext);
	if (context === undefined) {
		throw new Error("Context must be used within a Provider");
	}
	return context;
}